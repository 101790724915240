import React, { useState, useEffect } from 'react'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { deleteUserContact, addNewUserContact, updateUsers } from '~actions'
import { EditableTable } from '~components'
import './ContactInfo.css'

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstname'
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastname'
  },
  {
    title: 'Email',
    dataIndex: 'emailAddress',
    key: 'emailAddress'
  }
]

const userObj = {
  firstName: '',
  lastName: '',
  emailAddress: ''
}

const ContactInfo = ({ users, deleteUserContact, addNewUserContact }) => {
  const [usersList, setUsersList] = useState([])

  useEffect(() => {
    if (users) {
      setUsersList(users)
    }
  }, [users])

  const addUser = () => {
    const clonedUsers = [...users]
    clonedUsers.push({ ...userObj })
    addNewUserContact(clonedUsers)
  }
  const deleteUser = (index) => {
    const clonedUsers = [...users]
    clonedUsers.splice(index, 1)
    deleteUserContact(clonedUsers)
  }

  const onUserTableUpdate = (event, index) => {
    const clonedUsers = [...usersList]
    clonedUsers[index][event.target.name] = event.target.value
    updateUsers(clonedUsers)
  }

  return (
    <Row className='company-info'>
      <Col span={24}>
        <EditableTable
          addNewUserContact={addUser}
          columns={columns}
          deleteUserContact={deleteUser}
          onUserTableUpdate={onUserTableUpdate}
          users={usersList}
        />
      </Col>
    </Row>
  )
}

ContactInfo.propTypes = {
  users: PropTypes.array,
  deleteUserContact: PropTypes.func,
  addNewUserContact: PropTypes.func
}

const mapStateToProps = ({ contactInfo }) => ({
  users: contactInfo.users
})

const mapDispatchToProps = {
  deleteUserContact,
  addNewUserContact,
  updateUsers
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo)
