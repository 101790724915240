import { sumaryReportEndpoint, sumaryReportBySubOrgEndpoint, filterReportByTypeEndpoint } from '~apiConfig'
import { getApiResponseInJSON } from '~utils'
import { SUMMARY_REPORT, SUMMARY_REPORT_BYSUB_ORG, SUMMARY_REPORT_BY_SUB_ORG_TYPE, SUMMARY_REPORT_BY_SUB_ORG_COMPANY_CD } from '../actionTypes'

export const getSummaryReport = subOrgName => async (dispatch) => {
  const { url } = sumaryReportEndpoint.report(subOrgName)
  const apiResponse = await fetch(url)
    .then(async (response) => await getApiResponseInJSON(response))
    .catch((error) => console.error(error))

  dispatch({
    type: SUMMARY_REPORT,
    payload: {
      summary: apiResponse
    }
  })
}

export const getSumaryReportBySubOrg = subOrgName => async (dispatch) => {
  const { url } = sumaryReportBySubOrgEndpoint.report(subOrgName)
  const apiResponse = await fetch(url)
    .then(async (response) => await getApiResponseInJSON(response))
    .catch((error) => console.error(error))

  dispatch({
    type: SUMMARY_REPORT_BYSUB_ORG,
    payload: {
      summaryByOrg: apiResponse
    }
  })
}

export const getSumaryReportByType = (subOrgName, type) => async (dispatch) => {
  const { url } = filterReportByTypeEndpoint.reportByType(type)
  const apiResponse = await fetch(url)
    .then(async (response) => await getApiResponseInJSON(response))
    .catch((error) => console.error(error))

  dispatch({
    type: SUMMARY_REPORT_BY_SUB_ORG_TYPE,
    payload: {
      reportByType: apiResponse
    }
  })
}

export const getSumaryReportByCompanyCd = (type, companyCd) => async (dispatch) => {
  const { url } = filterReportByTypeEndpoint.reportByCompanyName(type, companyCd)
  const apiResponse = await fetch(url)
    .then(async (response) => await getApiResponseInJSON(response))
    .catch((error) => console.error(error))

  dispatch({
    type: SUMMARY_REPORT_BY_SUB_ORG_COMPANY_CD,
    payload: {
      reportByCompanyCd: apiResponse
    }
  })
}
