import React, { useEffect, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { Row, message, Upload } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { uploadFileToStore } from '~actions'

import './BulkUpload.css'

const { Dragger } = Upload

const FILE_UPLOAD_SIZE = 5

const BulkUpload = ({ uploadedFile = {}, uploadFileToStore }) => {
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (uploadedFile && uploadedFile.file) {
      setFileList(uploadedFile.file)
    }
  }, [uploadedFile])

  const props = {
    name: 'file',
    accept: '.csv,.xlsx,.xls',
    beforeUpload: (file) => {
      const isLt2M = file.size / 1024 / 1024 < FILE_UPLOAD_SIZE
      if (!isLt2M) {
        return message.error(
          `Document must smaller than ${FILE_UPLOAD_SIZE}MB!`
        )
      }
      setFileList([file])
      uploadFileToStore([file])
    },
    fileList,
    maxCount: 1,
    onRemove: () => {
      setFileList([])
      uploadFileToStore([])
    }
  }

  return (
    <Row className='bulk-upload'>
      <Dragger {...props}>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>
          Drag and drop your file here
          <br />
          or browse your computer
        </p>
      </Dragger>
    </Row>
  )
}

BulkUpload.propTypes = {
  uploadFileToStore: PropTypes.func,
  uploadedFile: PropTypes.object
}

const mapStateToProps = ({ bulkUpload }) => ({
  uploadedFile: bulkUpload.uploadedFile
})

const mapDispatchToProps = {
  uploadFileToStore
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkUpload)
