import React, { useEffect, useState } from 'react'
import { LoadingOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Card, Spin, Row, Table } from 'antd'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSummaryReport, getSumaryReportBySubOrg, getSumaryReportByCompanyCd } from '~actions'
import { Header, Footer, calculateColumnsWidth } from '~components'

import './ReportingByCompanyType.css'

const REPORT_TYPE = {
  1: 'Email Sent',
  2: 'Email Opened',
  3: 'UnSubscribe Email',
  4: 'Bounce Email',
  5: 'Clicked Email'
}

const antIcon = <LoadingOutlined spin style={{ fontSize: 48 }} />

const ReportingByCompanyCd = ({ getSummaryReport, summary, summaryByOrg, getSumaryReportByCompanyCd, reportByCompanyCd }) => {
  const { type, companyCd } = useParams()
  const [showSpinner, setShowSpinner] = useState(false)
  const [reportTypeResponse, setReportTypeResponse] = useState(null)
  const [showBackButton, setShowBackButton] = useState(null)

  useEffect(() => {
    if (companyCd && type) {
      setShowSpinner(true)
      getSumaryReportByCompanyCd(type, companyCd)
    }
  }, [getSumaryReportByCompanyCd, getSummaryReport, companyCd, type])

  useEffect(() => {
    if (reportByCompanyCd) {
      setShowSpinner(false)
      setShowBackButton(true)
      setReportTypeResponse(reportByCompanyCd)
    }
  }, [reportByCompanyCd])

  useEffect(() => {
    if (summaryByOrg) {
      setShowSpinner(false)
    }
  }, [summaryByOrg])

  useEffect(() => {
    if (summary) {
      setShowSpinner(false)
    }
  }, [summary])

  const handleBack = () => {
    setReportTypeResponse(null)
    setShowBackButton(false)
    window.location = `/admin/abc/summary-report/${companyCd}/0`
  }

  const maxWidthPerCell = 200
  const tableHeight = 500

  const columns = [
    {
      title: 'Chapter',
      label: 'Chapter',
      dataIndex: 'chapterName',
      key: 'chapterName'
    },
    {
      title: 'Company',
      label: 'Company',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: 'Employee First Name',
      label: 'Employee First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => <>{record.contactFirstName || record.firstName}</>
    },
    {
      title: 'Employee Last Name',
      label: 'Employee Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, record) => <>{record.contactLastName || record.lastName}</>
    },
    {
      title: 'Employee Email',
      label: 'Email',
      dataIndex: 'emailAddress',
      key: 'emailAddress'
    },
    {
      title: 'Date/Time',
      label: 'Date/Time',
      dataIndex: 'dateEmailSent',
      key: 'dateEmailSent'
    }
  ]
  const dataTable = reportTypeResponse && calculateColumnsWidth(columns, reportTypeResponse, maxWidthPerCell)

  return (
    <>
      <Header />
      <div className='page-content'>
        <Row className='page-header'>
          <div className='desktop' style={{ width: 0, marginRight: 12 }} />
          <div className='logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/CURE_Logo.png' />
            </a>
          </div>
          <div className='div-between-headers desktop' style={{ width: 98 }} />
          <div className='abc-logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/ABC-Michigan-Combined-Logo_v3.png' />
            </a>
          </div>
        </Row>
        <Row className='home report-by-cd'>
          {
            reportTypeResponse && <Card
              bordered={false}
              className='input-card'
              title={<>{showBackButton && <Button className='back-button' icon={<LeftOutlined style={{ fontSize: 16 }} />} onClick={() => handleBack()} shape="default" />}{`Company - ${type === '-1' ? 'Employees Enrolled' : REPORT_TYPE[type]} Report`}</>}
            >
              <Button className='btn'>
                <CSVLink
                  data={reportTypeResponse}
                  filename='report'
                  headers={columns}
                >
                  <img src="https://assets.cure.com/abc/img/excel.png" />
                </CSVLink>
              </Button>
              <Table
                columns={dataTable.columns}
                dataSource={reportTypeResponse}
                height={tableHeight}
                pagination={{ pageSize: 50 }}
                scroll={{ x: dataTable.tableWidth, y: tableHeight }}
                source={dataTable.source}
              />
            </Card>
          }
        </Row>
        {showSpinner && <Spin className='report-spinner' indicator={antIcon} />}
      </div>
      <Footer />
    </>
  )
}

ReportingByCompanyCd.propTypes = {
  getSumaryReportBySubOrg: PropTypes.func,
  getSumaryReportByCompanyCd: PropTypes.func,
  getSummaryReport: PropTypes.func,
  reportByCompanyCd: PropTypes.object,
  summary: PropTypes.object,
  summaryByOrg: PropTypes.object
}

const mapStateToProps = ({
  report
}) => ({
  summary: report.summary,
  summaryByOrg: report.summaryByOrg,
  reportByCompanyCd: report.reportByCompanyCd
})

const mapDispatchToProps = {
  getSummaryReport,
  getSumaryReportBySubOrg,
  getSumaryReportByCompanyCd
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingByCompanyCd)
