import React, { useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Input, Button } from 'antd'
import PropTypes from 'prop-types'
import { DeleteIcon } from '../SVGComponent/SVGComponent'

import './EditableTable.css'

const UserRow = ({
  index,
  totalUser,
  onUserTableUpdate,
  user,
  deleteUserContact,
  addNewUserContact
}) => {
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [emailErrorValidation, setEmailErrorValidation] = useState()

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmailAddress(user.emailAddress)
    }
  }, [user])

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value)
  }

  const onLastNameChange = (event) => {
    setLastName(event.target.value)
  }

  const onEmailChange = (event) => {
    setEmailAddress(event.target.value)
  }

  const addNewUser = () => {
    addNewUserContact()
  }

  const validateEmail = (email) => {
    const regEx = /\S+@\S+\.\S+/
    let validationResponse = null
    if (email.length > 0) {
      if (regEx.test(email)) {
        validationResponse = null
      } else {
        validationResponse = 'Email Address is invalid'
      }
    }
    setEmailErrorValidation(validationResponse)
  }

  return (
    <tr className='eachRow'>
      <td className='del-cell mobile'>
        {index > 0 && (
          <Button
            className='delete-item'
            icon={<DeleteIcon height={26} width={26} />}
            onClick={() => deleteUserContact(index)}
            shape='circle'
            size='large'
            type='primary'
          />
        )}
        {index === totalUser - 1 && (
          <Button
            className='add-new-item position-absolute'
            icon={<PlusOutlined />}
            onClick={addNewUser}
            shape='circle'
            size='large'
            type='primary'
          />
        )}
      </td>
      <td style={{ width: '25%' }}>
        <Input
          disabled={index === 0}
          name='firstName'
          onBlur={(event) => onUserTableUpdate(event, index)}
          onChange={onFirstNameChange}
          placeholder='First Name'
          value={firstName}
        />
        {emailErrorValidation && <td className='error-message'>&nbsp;</td>}
      </td>
      <td style={{ width: '25%' }}>
        <Input
          disabled={index === 0}
          name='lastName'
          onBlur={(event) => onUserTableUpdate(event, index)}
          onChange={onLastNameChange}
          placeholder='Last Name'
          value={lastName}
        />
        {emailErrorValidation && <td className='error-message'>&nbsp;</td>}
      </td>
      <td>
        <Input
          disabled={index === 0}
          name='emailAddress'
          onBlur={(event) => {
            validateEmail(event.target.value)
            onUserTableUpdate(event, index)
          }}
          onChange={onEmailChange}
          placeholder='Email'
          value={emailAddress}
        />
        {emailErrorValidation && (
          <td className='error-message'>{emailErrorValidation}</td>
        )}
      </td>
      <td className='del-cell desktop'>
        {index > 0 && (
          <Button
            className='delete-item'
            icon={<DeleteIcon />}
            onClick={() => deleteUserContact(index)}
            shape='circle'
            size='large'
            type='primary'
          />
        )}
        {index === totalUser - 1 && (
          <Button
            className='add-new-item position-absolute'
            icon={<PlusOutlined />}
            onClick={addNewUser}
            shape='circle'
            size='large'
            type='primary'
          />
        )}
      </td>
    </tr>
  )
}

UserRow.propTypes = {
  index: PropTypes.number,
  totalUser: PropTypes.number,
  onUserTableUpdate: PropTypes.func,
  deleteUserContact: PropTypes.func,
  addNewUserContact: PropTypes.func,
  user: PropTypes.object
}

const EditableTable = ({
  columns,
  users,
  onUserTableUpdate,
  deleteUserContact,
  addNewUserContact
}) => {
  const usersRow = users.map((user, index) => (
    <UserRow
      key={index}
      addNewUserContact={addNewUserContact}
      deleteUserContact={deleteUserContact}
      index={index}
      onUserTableUpdate={onUserTableUpdate}
      totalUser={users.length}
      user={user}
    />
  ))
  return (
    <table className='table table-bordered'>
      <thead className='table-thead-dekstop'>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>{usersRow}</tbody>
    </table>
  )
}

EditableTable.propTypes = {
  columns: PropTypes.array,
  users: PropTypes.array,
  onUserTableUpdate: PropTypes.func,
  deleteUserContact: PropTypes.func,
  addNewUserContact: PropTypes.func
}

export default EditableTable
