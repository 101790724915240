import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import admin from './admin/reducers'
import bulkUpload from './bulkupload/reducers'
import companyInfo from './companyInfo/reducers'
import contactInfo from './contactInfo/reducers'
import employeeInfo from './employeeInfo/reducers'
import home from './home/reducers'
import report from './report/reducers'
import uploadByColleague from './uploadbycolleague/reducers'

export default (history) =>
  combineReducers({
    admin,
    bulkUpload,
    companyInfo,
    contactInfo,
    employeeInfo,
    home,
    report,
    router: connectRouter(history),
    uploadByColleague
  })
