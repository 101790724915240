import React, { useEffect, useState } from 'react'
import { LoadingOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Card, Spin, Row } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSummaryReport, getSumaryReportBySubOrg, getSumaryReportByType } from '~actions'
import { Header, Footer, ReportTable } from '~components'

import './ReportingByType.css'

const antIcon = <LoadingOutlined spin style={{ fontSize: 48 }} />
const REPORT_TYPE = {
  0: 'Companies Enrolled',
  1: 'Email Sent',
  2: 'Email Opened',
  3: 'UnSubscribe Email',
  4: 'Bounce Email',
  5: 'Clicked Email'
}

const ReportingByType = ({ getSummaryReport, summary, summaryByOrg, getSumaryReportByType, reportByType }) => {
  const { subOrg, type } = useParams()
  const [showSpinner, setShowSpinner] = useState(false)
  const [reportTypeResponse, setReportTypeResponse] = useState(null)
  const [showBackButton, setShowBackButton] = useState(null)

  useEffect(() => {
    if (subOrg) {
      setShowSpinner(true)
      getSumaryReportByType(subOrg, type)
    }
  }, [getSumaryReportByType, getSummaryReport, subOrg, type])

  useEffect(() => {
    if (reportByType) {
      setShowSpinner(false)
      setShowBackButton(true)
      setReportTypeResponse(reportByType)
    }
  }, [reportByType])

  useEffect(() => {
    if (summaryByOrg) {
      setShowSpinner(false)
    }
  }, [summaryByOrg])

  useEffect(() => {
    if (summary) {
      setShowSpinner(false)
    }
  }, [summary])

  const handleBack = () => {
    setReportTypeResponse(null)
    setShowBackButton(false)
    window.location = '/admin/abc/summary-report'
  }

  return (
    <>
      <Header />
      <div className='page-content'>
        <Row className='page-header'>
          <div className='desktop' style={{ width: 0, marginRight: 12 }} />
          <div className='logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/CURE_Logo.png' />
            </a>
          </div>
          <div className='div-between-headers desktop' style={{ width: 98 }} />
          <div className='abc-logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/ABC-Michigan-Combined-Logo_v3.png' />
            </a>
          </div>
        </Row>
        <Row className='home'>
          <Card
            bordered={false}
            className='input-card'
            title={<>{showBackButton && <Button className='back-button' icon={<LeftOutlined style={{ fontSize: 16 }} />} onClick={() => handleBack()} shape="default" />}{`Summary Report - ${type === '-1' ? 'Employees Enrolled' : REPORT_TYPE[type]}`}</>}
          >
            <Row className='upload-per-colleague'>
              {
                reportTypeResponse && <ReportTable dataSource={reportByType} />
              }
            </Row>
            {showSpinner && <Spin className='report-spinner' indicator={antIcon} />}
          </Card>
        </Row>
      </div>
      <Footer />
    </>
  )
}

ReportingByType.propTypes = {
  getSumaryReportBySubOrg: PropTypes.func,
  getSumaryReportByType: PropTypes.func,
  getSummaryReport: PropTypes.func,
  reportByType: PropTypes.object,
  summary: PropTypes.object,
  summaryByOrg: PropTypes.object
}

const mapStateToProps = ({
  report
}) => ({
  summary: report.summary,
  summaryByOrg: report.summaryByOrg,
  reportByType: report.reportByType
})

const mapDispatchToProps = {
  getSummaryReport,
  getSumaryReportBySubOrg,
  getSumaryReportByType
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingByType)
