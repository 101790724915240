import React from 'react'
import { Layout } from 'antd'
import { Redirect, Route, Switch } from 'react-router-dom'
import { MainLayout } from '~containers'
import { Enrollment, NotFound, Reporting, ReportingByType, ReportingByCompanyCd, ReportingByCompanyType, Unsubscribe } from '~pages'
import { RoutesList } from '~routesConfig'

import 'antd/dist/antd.css'
import './App.css'

const App = () => {
  const withLayout = (Component, Layout) => (route) => <Layout component={Component} route={route} /> // eslint-disable-line react/display-name

  const buildRoutes = (routes) =>
    routes.reduce(
      (RouteList, { component, path, layout = MainLayout, redirectTo }) => {
        const routeComponent = redirectTo
          ? () => <Redirect to={redirectTo} />
          : withLayout(component, layout)

        return [
          ...RouteList,
          <Route key={path} component={routeComponent} exact path={path} />
        ]
      },
      []
    )

  const routes = buildRoutes(RoutesList)

  return (
    <Layout className='main-container'>
      <Switch>
        {routes}
        <Route component={Enrollment} exact path='/' />
        <Route component={Reporting} exact path='/admin/:subOrg/summary-report' />
        <Route component={ReportingByType} exact path='/admin/:subOrg/summary-report/:type' />
        <Route component={ReportingByCompanyCd} exact path='/admin/:subOrg/summary-report/:companyCd/:type' />
        <Route component={ReportingByCompanyType} exact path='/admin/:subOrg/summary-report/:companyCd/report/:type' />
        <Route component={Unsubscribe} exact path='/unsubscribe' />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  )
}

export default App
