import React, { useCallback, useEffect, useState } from 'react'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { Card, Button, Row, Col, Typography } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  addFormBasedCollegues,
  handleStepUpdate,
  sendEmail,
  uploadResources,
  validateFileUploadOfColleagues
} from '~actions'
import { Header, Footer } from '~components'
import { history } from '~history'

import './MainLayout.css'
import { COLLEGUES_MAP } from '../../constants'

const { Link } = Typography
const steps = [
  {
    title: 'Step1'
  },
  {
    title: 'Step2'
  },
  {
    title: 'Step3'
  }
]

const MainLayout = ({
  addFormBasedCollegues,
  chapters,
  companyDetail,
  component: Component,
  currentStep,
  handleStepUpdate,
  route,
  selectedOption,
  sendEmail,
  uploadedFile,
  users,
  validateFileUploadOfColleagues
}) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  useEffect(() => {
    if (companyDetail && currentStep === 0) {
      const validationResponse = validateCompanyInfoForm(companyDetail)
      if (!validationResponse) {
        setDisableButton(false)
      } else {
        setDisableButton(true)
      }
    }
  }, [companyDetail, currentStep])

  const validateEmployeeInfoForm = useCallback(() => {
    if (selectedOption > 0) {
      if (
        selectedOption === 2 &&
        uploadedFile &&
        uploadedFile.file &&
        uploadedFile.file.length > 0
      ) {
        setDisableButton(false)
      } else if (selectedOption === 1) {
        setDisableButton(false)
      } else {
        setDisableButton(true)
      }
    } else {
      setDisableButton(true)
    }
  }, [selectedOption, uploadedFile])

  useEffect(() => {
    if (currentStep === 1) {
      validateEmployeeInfoForm(selectedOption)
    }
  }, [selectedOption, currentStep, validateEmployeeInfoForm])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const validateCompanyInfoForm = (companyDetail) => {
    const regEx = /\S+@\S+\.\S+/
    const {
      chapter,
      deputyTitle,
      deputyEmailAddress,
      deputyFirstName,
      deputyLastName,
      orgAddress1,
      city,
      zipCode,
      orgName,
      phoneNumber
    } = companyDetail
    const errorFields = []
    if (!chapter || chapter.length === 0) {
      errorFields.push('Chapter Name')
    }
    if (!orgName || orgName.length === 0) {
      errorFields.push('Company Name')
    }
    if (!orgAddress1 || orgAddress1.length === 0) {
      errorFields.push('Org Address1')
    }
    if (!city || city.length === 0) {
      errorFields.push('City')
    }
    if (!zipCode || zipCode.length === 0) {
      errorFields.push('Zip Code')
    }
    if (!deputyFirstName || deputyFirstName.length === 0) {
      errorFields.push('Deputy FirstName')
    }
    if (!deputyLastName || deputyLastName.length === 0) {
      errorFields.push('Deputy LastName')
    }
    if (!deputyTitle || deputyTitle.length === 0) {
      errorFields.push('Deputy Title')
    }
    if (!deputyEmailAddress || deputyEmailAddress.length === 0) {
      errorFields.push('Deputy EmailAddress')
    } else if (
      deputyEmailAddress &&
      deputyEmailAddress.length > 0 &&
      !regEx.test(deputyEmailAddress)
    ) {
      errorFields.push('Deputy EmailAddress')
    }
    if (!phoneNumber || phoneNumber.length === 0) {
      errorFields.push('Phone Number')
    }
    return errorFields.length > 0
  }

  const getFormattedData = (data) => {
    const {
      city,
      deputyEmailAddress,
      deputyFirstName,
      deputyLastName,
      deputyTitle,
      orgAddress1,
      orgAddress2,
      orgName: subOrgName,
      orgPresidentFirstName,
      orgPresidentLastName,
      phoneNumber,
      state,
      zipCode,
      chapter,
      file: colleagues,
      users
    } = data

    const companyChapterId = chapters.filter(
      (option) => option.label === chapter
    )?.[0].id
    const usersList = colleagues || users
    const updatedColleagues = usersList.map((colleague) => ({
      emailAddress: colleague.emailAddress,
      firstName: colleague.firstName,
      lastName: colleague.lastName
    }))

    return {
      companyChapterId,
      subOrgName,
      orgAddress1,
      orgAddress2,
      city,
      state,
      zipCode,
      deputyFullName: `${deputyFirstName} ${deputyLastName}`,
      deputyFirstName,
      deputyLastName,
      deputyTitle,
      deputyEmailAddress,
      phoneNumber,
      orgPresidentFirstName,
      orgPresidentLastName,
      colleagues: updatedColleagues
    }
  }

  const buildTable = data => {
    let htmlString = '<html><body><table border=\'1\' style=\'width: 500px;\'>'
    for (const key in data) {
      if (typeof data[key] === 'string' || typeof data[key] === 'number') {
        if (key === 'companyChapterId') {
          const companyChapterName = chapters.filter(
            (option) => option.id === data[key]
          )?.[0].label
          htmlString += `<tr><td>${COLLEGUES_MAP[key].toUpperCase()}</td><td>${companyChapterName}</td></tr>`
        } else {
          htmlString += `<tr><td>${COLLEGUES_MAP[key].toUpperCase()}</td><td>${data[key]}</td></tr>`
        }
      }
    }
    htmlString += '</table><br /><table border=\'1\' style=\'width: 500px;\'>'
    for (const key in data) {
      if (typeof data[key] === 'object') {
        htmlString += '<tr><td colspan=\'3\' style=\'text-align: center;\'><b>Employee Information</b></td></tr>'
        htmlString += '<tr><th>First Name</th><th>Last Name</th><th>Email Address</th></tr>'
        const employees = data[key]
        employees.map(employee => {
          if (employee && employee.firstName) {
            htmlString += `<tr><td>${employee.firstName}</td><td>${employee.lastName}</td><td>${employee.emailAddress}</td></tr>`
          }
        })
      }
    }
    htmlString += '</table></body></html>'
    return htmlString
  }

  const handleNextClick = async () => {
    setDisableButton(true)
    if (currentStep === 0) {
      const validationResponse = validateCompanyInfoForm(companyDetail)
      if (!validationResponse) {
        setDisableButton(false)
        handleStepUpdate(currentStep + 1)
      } else {
        setDisableButton(true)
      }
    } else if (currentStep === 1) {
      setShowSpinner(true)
      const validationResponse = validateEmployeeInfoForm(selectedOption)
      if (!validationResponse) {
        setDisableButton(false)
        handleStepUpdate(currentStep + 1)
      } else {
        setDisableButton(true)
      }
      if (selectedOption === 2) {
        const formData = new FormData()
        formData.append('file', uploadedFile?.file[0])
        validateFileUploadOfColleagues(formData)
      }
    } else if (currentStep === 2) {
      setShowSpinner(true)
      if (selectedOption === 1) {
        const data = {
          ...companyDetail,
          file: users
        }
        const formattedData = getFormattedData(data)
        await addFormBasedCollegues(formattedData)
        const htmlText = buildTable(formattedData)
        const mailObj = {
          subject: `Company Name - ${formattedData.subOrgName}`,
          content: htmlText
        }

        await sendEmail(mailObj)
        handleStepUpdate(currentStep + 1)
      } else if (selectedOption === 2) {
        const data = {
          ...companyDetail,
          users
        }
        const formattedData = getFormattedData(data)
        await addFormBasedCollegues(formattedData)

        const htmlText = buildTable(formattedData)
        const mailObj = {
          subject: `Company Name - ${formattedData.subOrgName}`,
          content: htmlText
        }

        await sendEmail(mailObj)
        handleStepUpdate(currentStep + 1)
      }
    } else {
      handleStepUpdate(currentStep + 1)
    }
    scrollToTop()
  }

  const handleBackClick = () => {
    scrollToTop()
    handleStepUpdate(currentStep - 1)
  }

  useEffect(() => {
    let routingPage = ''

    if (currentStep === 0) {
      routingPage = '/company-info'
    } else if (currentStep === 1) {
      routingPage = '/employee-info'
    } else if (currentStep === 2) {
      routingPage = '/review'
    } else if (currentStep === 3) {
      routingPage = '/result'
    }
    history.push(routingPage)
  }, [currentStep, selectedOption])

  return (
    <>
      <Header />
      <div className='page-content'>
        <Row className='page-header'>
          <div className='desktop' style={{ width: 0, marginRight: 12 }} />
          <div className='logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/CURE_Logo.png' />
            </a>
          </div>
          <div className='desktop' style={{ width: 98 }} />
          <div className='abc-logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/ABC-Michigan-Combined-Logo_v3.png' />
            </a>
          </div>
        </Row>
        <Row className='home'>
          <Card
            bordered={false}
            className='input-card'
            title='ABC Michigan Member Enrollment'
          >
            <Stepper activeStep={currentStep} className='modile-stepper'>
              {steps.map((item, index) => (
                <Step key={index}>
                  <StepLabel> </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Component route={route} />
          </Card>
          <Row style={{ width: '100%', background: 'white' }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              {currentStep !== 3 && (
                <Button
                  className='prev-button'
                  onClick={handleBackClick}
                  type='primary'
                >
                  BACK
                </Button>
              )}
              {currentStep < steps.length - 1 && (
                <Button
                  className='next-button'
                  disabled={disableButton}
                  onClick={handleNextClick}
                  style={{ marginLeft: 18 }}
                  type='primary'
                >
                  NEXT
                </Button>
              )}
              {currentStep === steps.length - 1 && currentStep === 0 && (
                <Button className='next-button' href='/' type='primary'>
                  START
                </Button>
              )}
              {currentStep === steps.length - 1 && currentStep > 0 && (
                <Button
                  className='next-button'
                  loading={showSpinner}
                  onClick={handleNextClick}
                  style={{ marginLeft: currentStep > 0 ? 18 : 0 }}
                  type='primary'
                >
                  SUBMIT
                </Button>
              )}
            </Col>
          </Row>
          {currentStep !== 3 && (
            <Row
              style={{
                width: '100%',
                background: 'white',
                paddingTop: 12,
                paddingBottom: 12
              }}
            >
              <Col span={24} style={{ textAlign: 'center', fontSize: 18 }}>
                Questions?{' '}
                <Link
                  className='question-link'
                  href='mailto:ABCMichigan@cure.com'
                >
                  ABCMichigan@cure.com
                </Link>
              </Col>
            </Row>
          )}
        </Row>
      </div>
      <Footer />
    </>
  )
}

MainLayout.propTypes = {
  addFormBasedCollegues: PropTypes.func,
  chapters: PropTypes.array,
  companyDetail: PropTypes.object,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  currentStep: PropTypes.number,
  handleStepUpdate: PropTypes.func,
  route: PropTypes.object.isRequired,
  selectedOption: PropTypes.number,
  sendEmail: PropTypes.func,
  uploadedFile: PropTypes.object,
  users: PropTypes.array,
  validateFileUploadOfColleagues: PropTypes.func
}

const mapStateToProps = ({
  companyInfo,
  employeeInfo,
  bulkUpload,
  contactInfo
}) => {
  return {
    chapters: companyInfo.chapters,
    companyDetail: companyInfo.companyDetail,
    currentStep: companyInfo.currentStep,
    selectedOption: employeeInfo.selectedOption,
    uploadedFile: bulkUpload.uploadedFile,
    users: contactInfo.users
  }
}

const mapDispatchToProps = {
  addFormBasedCollegues,
  handleStepUpdate,
  sendEmail,
  uploadResources,
  validateFileUploadOfColleagues
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
