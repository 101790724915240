import React from 'react'
import { Card, Row } from 'antd'
import { Header, Footer } from '~components'
import './404.css'

const NotFound = () => {
  return (
    <div className='not-found-container'>
      <Header />
      <div className='page-content'>
        <Row className='page-header'>
          <div className='desktop' style={{ width: 0, marginRight: 12 }} />
          <div className='logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/CURE_Logo.png' />
            </a>
          </div>
          <div className='div-between-headers desktop' style={{ width: 98 }} />
          <div className='abc-logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/ABC-Michigan-Combined-Logo_v3.png' />
            </a>
          </div>
        </Row>
        <Row className='home unsubscribe'>
          <Card
            bordered={false}
            className='input-card'
            title='SORRY'
          >

            <Row className='upload-per-colleague'>
              <p>The page or address you are looking for could not be found.</p>
            </Row>
          </Card>
        </Row>
      </div>
      <Footer />
    </div>
  )
}

NotFound.propTypes = {}

export default NotFound
