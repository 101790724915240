import React from 'react'
import { Card, Row, Typography } from 'antd'

import './Results.css'

const { Text } = Typography

const Results = () => (
  <>
    <Row className='result'>
      <Card style={{ width: '100%' }} title=''>
        <Row className='result-row'>
          <Text className='title-text'>
            Thank you for providing all the details!
          </Text>
          <Text>
            Now enrolled, your employees will receive a personalized email from
            CURE inviting them to get a custom quote that includes the ABC
            Member 10% discount. The email will include a special link along
            with our phone number if they prefer to speak with a Customer
            Relations representative. Please be sure they mention ABC Michigan
            when calling.
          </Text>
          <Text className='sub-text'>
            If you have questions, please email us at{' '}
            <a className='sub-text' href='mailto:ABCMichigan@cure.com'>
              ABCMichigan@cure.com.
            </a>
          </Text>
        </Row>
      </Card>
    </Row>
  </>
)

Results.propTypes = {}

export default Results
