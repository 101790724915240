import {
  GET_CHAPTERS,
  UPDATE_STEP,
  UPDATE_COMPANY_DETAIL,
  UPDATE_EMPLOYEES
} from '../actionTypes'

const initialState = {
  currentStep: 0,
  companyDetail: {
    city: '',
    deputyEmailAddress: '',
    deputyFirstName: '',
    deputyLastName: '',
    deputyTitle: '',
    orgAddress1: '',
    orgAddress2: '',
    orgName: '',
    orgPresidentFirstName: '',
    orgPresidentLastName: '',
    phoneNumber: '',
    companyChapterId: null,
    state: 'MI',
    zipCode: ''
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_STEP:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_COMPANY_DETAIL:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_EMPLOYEES:
      return {
        ...state,
        ...action.payload
      }
    case GET_CHAPTERS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
