import React, { useState, useEffect } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Row, Typography, Spin } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { addFormBasedCollegues, uploadResources } from '~actions'

import './Result.css'
const { Title } = Typography

const antIcon = <LoadingOutlined spin style={{ fontSize: 48 }} />

const Result = ({
  addFormBasedCollegues,
  companyDetail,
  publishMessage,
  selectedOption,
  uploadedFile,
  uploadResponse,
  uploadResources,
  users
}) => {
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    if (selectedOption === 1) {
      const data = {
        ...companyDetail,
        colleagues: users
      }
      addFormBasedCollegues(data)
    } else if (selectedOption === 2) {
      const data = {
        ...companyDetail,
        file: users
      }
      const formData = new FormData()
      formData.append('companyDetail', companyDetail)
      formData.append('file', uploadedFile.file[0])
      uploadResources(data)
    }
  }, [
    selectedOption,
    addFormBasedCollegues,
    uploadResources,
    companyDetail,
    uploadedFile,
    users
  ])

  useEffect(() => {
    if (uploadResponse && uploadResponse.message) {
      setShowSpinner(false)
    } else {
      setShowSpinner(true)
    }
  }, [uploadResponse])

  useEffect(() => {
    if (publishMessage && publishMessage.message) {
      setShowSpinner(false)
    } else {
      setShowSpinner(true)
    }
  }, [publishMessage])

  return (
    <Row className='upload-per-colleague'>
      {uploadResponse && uploadResponse.message && (
        <Title level={3}>{uploadResponse.message}</Title>
      )}
      {publishMessage && publishMessage.message && (
        <Title level={3}>{publishMessage.message}</Title>
      )}
      {showSpinner && <Spin className='spinner' indicator={antIcon} />}
    </Row>
  )
}

Result.propTypes = {
  addFormBasedCollegues: PropTypes.func,
  companyDetail: PropTypes.object,
  message: PropTypes.string,
  publishMessage: PropTypes.object,
  selectedOption: PropTypes.number,
  uploadedFile: PropTypes.object,
  uploadResources: PropTypes.func,
  uploadResponse: PropTypes.object,
  users: PropTypes.array
}

const mapStateToProps = ({
  companyInfo,
  employeeInfo,
  contactInfo,
  bulkUpload,
  uploadByColleague
}) => ({
  companyDetail: companyInfo.companyDetail,
  publishMessage: uploadByColleague.publishMessage,
  selectedOption: employeeInfo.selectedOption,
  uploadedFile: bulkUpload.uploadedFile,
  uploadResponse: bulkUpload.uploadResponse,
  users: contactInfo.users
})

const mapDispatchToProps = {
  addFormBasedCollegues,
  uploadResources
}

export default connect(mapStateToProps, mapDispatchToProps)(Result)
