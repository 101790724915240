import {
  BulkUpload,
  CompanyInfo,
  ContactInfo,
  EmployeeInfo,
  Review,
  Results
} from './'

export const RoutesList = [
  {
    component: CompanyInfo,
    path: '/company-info'
  },
  {
    component: EmployeeInfo,
    path: '/employee-info'
  },
  {
    component: ContactInfo,
    path: '/contact-info'
  },
  {
    component: BulkUpload,
    path: '/bulk-upload'
  },
  {
    component: Results,
    path: '/result'
  },
  {
    component: Review,
    path: '/review'
  }
]
