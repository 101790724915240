import {
  LOAD_USER_LIST,
  PUBLISH_USER_LIST,
  RESET_ADMIN_MESSAGE,
  GET_ALL_SUB_ORGS
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_LIST:
      return {
        ...state,
        ...action.payload
      }
    case PUBLISH_USER_LIST:
      return {
        ...state,
        ...action.payload
      }
    case RESET_ADMIN_MESSAGE:
      return {
        ...state,
        ...action.payload
      }
    case GET_ALL_SUB_ORGS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
