import React from 'react'
import { Card, Row, Typography } from 'antd'
import { Header, Footer } from '~components'

import './Unsubscribe.css'

const { Text } = Typography

const Report = () => {
  return (
    <>
      <Header />
      <div className='page-content'>
        <Row className='page-header'>
          <div className='desktop' style={{ width: 0, marginRight: 12 }} />
          <div className='logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/CURE_Logo.png' />
            </a>
          </div>
          <div className='div-between-headers desktop' style={{ width: 98 }} />
          <div className='abc-logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/ABC-Michigan-Combined-Logo_v3.png' />
            </a>
          </div>
        </Row>
        <Row className='home unsubscribe'>
          <Card
            bordered={false}
            className='input-card'
            title='Unsubscribe'
          >

            <Row className='upload-per-colleague'>
              <Text className='sub-text'>Thank you</Text>
              <p>
                You have been successfully removed from our marketing email list
                and won&apos;t receive any further marketing emails from us.
              </p>
            </Row>
          </Card>
        </Row>
      </div>
      <Footer />
    </>
  )
}

Report.propTypes = {

}

export default Report
