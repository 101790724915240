import { bulkUploadEndpoint } from '~apiConfig'
import { getApiResponseInJSON } from '~utils'
import {
  GET_BULK_UPLOAD_DETAILS,
  UPLOAD_FILE_TO_STORE,
  RESOURCE_UPLOAD
} from '../actionTypes'

export const getBulkUploadDetails = (params) => async (dispatch) => {
  dispatch({
    payload: {
      params
    },
    type: GET_BULK_UPLOAD_DETAILS
  })
}

export const uploadFileToStore = (params) => async (dispatch) => {
  dispatch({
    payload: {
      file: params
    },
    type: UPLOAD_FILE_TO_STORE
  })
}

export const uploadResources = (formData) => async (dispatch) => {
  const { method, url } = bulkUploadEndpoint.resourceUpload
  const apiResponse = await fetch(url, {
    method,
    body: formData
  })
    .then(async (response) => await getApiResponseInJSON(response))
    .catch((error) => console.error(error))

  dispatch({
    type: RESOURCE_UPLOAD,
    payload: {
      uploadResponse: apiResponse
    }
  })
}
