import { adminEndpoint } from '~apiConfig'
import { getApiResponseInJSON } from '~utils'

import {
  GET_CHAPTERS,
  UPDATE_STEP,
  UPDATE_COMPANY_DETAIL,
  UPDATE_EMPLOYEES
} from '../actionTypes'

export const handleStepUpdate = (stepValue) => async (dispatch) => {
  dispatch({
    type: UPDATE_STEP,
    payload: {
      currentStep: stepValue
    }
  })
}

export const updateCompanyDetail = (companyDetail) => async (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_DETAIL,
    payload: {
      companyDetail
    }
  })
}

export const updateEmployees = (users) => async (dispatch) => {
  dispatch({
    type: UPDATE_EMPLOYEES,
    payload: {
      users
    }
  })
}

export const getCompanyChapters = () => async (dispatch) => {
  const { method, url } = adminEndpoint.companyChapters
  const apiResponse = await fetch(url, {
    method
  })
    .then(async (response) => await getApiResponseInJSON(response))
    .catch((error) => console.error(error))

  dispatch({
    type: GET_CHAPTERS,
    payload: {
      chapters: apiResponse.map((chapter) => ({
        id: chapter.id,
        label: chapter.chapterName,
        value: chapter.chapterName
      }))
    }
  })
}
