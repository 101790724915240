const { REACT_APP_API_URL, REACT_APP_ENV } = process.env

export const adminEndpoint = {
  users: (subOrgId) => ({
    method: 'POST',
    url: `${REACT_APP_API_URL}/admin/loadCampaign/abc/${subOrgId}`
  }),
  publish: (subOrgId) => ({
    method: 'POST',
    url: `${REACT_APP_API_URL}/admin/sendCampaign/abc/${subOrgId}`
  }),
  subOrgs: {
    method: 'POST',
    url: `${REACT_APP_API_URL}/admin/allSubOrgs/abc`
  },
  companyChapters: {
    method: 'GET',
    url: `${REACT_APP_API_URL}/cap/companyChapters`
  }
}

export const bulkUploadEndpoint = {
  resourceUpload: {
    method: 'POST',
    url: `${REACT_APP_API_URL}/cap/resourceUpload/abc`
  }
}

export const formBasedCollegueEndpoint = {
  addCollegues: {
    method: 'POST',
    url: `${REACT_APP_API_URL}/cap/addColleagues/abc`
  }
}

export const employeeEndpoint = {
  validateFileUploadOfColleagues: {
    method: 'POST',
    url: `${REACT_APP_API_URL}/cap/validateFileUploadOfColleagues`
  }
}

export const sumaryReportEndpoint = {
  report: subOrgName => ({
    method: 'GET',
    url: `${REACT_APP_API_URL}/admin/abc/generateReport`
  })
}

export const sumaryReportBySubOrgEndpoint = {
  report: subOrgName => ({
    method: 'GET',
    url: `${REACT_APP_API_URL}/admin/abc/${subOrgName}/generateReport`
  })
}

export const filterReportByTypeEndpoint = {
  reportByType: (type) => ({
    method: 'GET',
    url: `${REACT_APP_API_URL}/admin/reports/${type}`
  }),
  reportByCompanyName: (type, companyCode) => ({
    method: 'GET',
    url: `${REACT_APP_API_URL}/admin/reports/${companyCode}/${type}`
  })
}

export const emailEndpoint = {
  send: {
    method: 'POST',
    url: `${REACT_APP_API_URL}/cap/api/sendEmail?env=${REACT_APP_ENV}`
  }
}
