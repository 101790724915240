import { formBasedCollegueEndpoint, emailEndpoint } from '~apiConfig'
import { getApiResponseInJSON } from '~utils'
import {
  ADD_FORM_BASED_COLLEGUES,
  ADD_MORE_USER,
  GET_UPLOAD_BY_COLLEAGUE,
  SEND_EMAIL,
  UPDATE_ORG_DETAILS
} from '../actionTypes'

export const getUploadByColleague = (params) => async (dispatch) => {
  dispatch({
    type: GET_UPLOAD_BY_COLLEAGUE,
    payload: {
      params
    }
  })
}

export const addMoreUser = (params) => async (dispatch) => {
  dispatch({
    type: ADD_MORE_USER,
    payload: {
      orgDetail: params
    }
  })
}

export const updateOrgDetails = (params) => async (dispatch) => {
  dispatch({
    type: UPDATE_ORG_DETAILS,
    payload: {
      orgDetail: params
    }
  })
}

export const addFormBasedCollegues = (data) => async (dispatch) => {
  const { method, url } = formBasedCollegueEndpoint.addCollegues
  const apiResponse = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method
  })
    .then(async (response) => await getApiResponseInJSON(response))
    .catch((error) => console.error(error))

  dispatch({
    type: ADD_FORM_BASED_COLLEGUES,
    payload: {
      publishMessage: apiResponse
    }
  })
}

export const sendEmail = data => async (dispatch) => {
  const { method, url } = emailEndpoint.send
  const apiResponse = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method
  })
    .then(async (response) => await getApiResponseInJSON(response))
    .catch((error) => console.error(error))

  dispatch({
    type: SEND_EMAIL,
    payload: {
      sendEmailResponse: apiResponse
    }
  })
}
