import React, { useEffect, useState } from 'react'
import { LoadingOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Card, Spin, Row } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSummaryReport, getSumaryReportBySubOrg, getSumaryReportByCompanyCd } from '~actions'
import { Header, Footer } from '~components'

import './ReportingByCompanyCd.css'

const antIcon = <LoadingOutlined spin style={{ fontSize: 48 }} />

const ReportingByCompanyCd = ({ getSummaryReport, summary, summaryByOrg, getSumaryReportByCompanyCd, reportByCompanyCd }) => {
  const { type, companyCd } = useParams()
  const [showSpinner, setShowSpinner] = useState(false)
  const [reportTypeResponse, setReportTypeResponse] = useState(null)
  const [showBackButton, setShowBackButton] = useState(null)

  const handleClick = (recordType) => {
    window.location = `/admin/abc/summary-report/${companyCd}/report/${recordType}`
  }

  useEffect(() => {
    if (companyCd && type) {
      setShowSpinner(true)
      getSumaryReportByCompanyCd(type, companyCd)
    }
  }, [getSumaryReportByCompanyCd, getSummaryReport, companyCd, type])

  useEffect(() => {
    if (reportByCompanyCd) {
      setShowSpinner(false)
      setShowBackButton(true)
      setReportTypeResponse(reportByCompanyCd)
    }
  }, [reportByCompanyCd])

  useEffect(() => {
    if (summaryByOrg) {
      setShowSpinner(false)
    }
  }, [summaryByOrg])

  useEffect(() => {
    if (summary) {
      setShowSpinner(false)
    }
  }, [summary])

  const handleBack = () => {
    setReportTypeResponse(null)
    setShowBackButton(false)
    window.location = `/admin/abc/summary-report/${type}`
  }

  const [summaryReport] = reportTypeResponse ?? []
  const {
    bounceEmails,
    chapterName,
    cickedEmails,
    city,
    companyName,
    contactEmail,
    contactFirstName,
    contactLastName,
    contactPhone,
    emailSent,
    employedEnrolled,
    openEmails,
    orgAddress1,
    orgAddress2,
    presidentFirstName,
    presidentLastName,
    state,
    unsubscribEmails,
    zipCode
  } = summaryReport ?? {}

  let updatedEmployeeEnrolled = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summaryReport && employedEnrolled && employedEnrolled !== '') {
    updatedEmployeeEnrolled = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(-1)}>{employedEnrolled}</a></td><td>&nbsp;</td><td>&nbsp;</td></>
  }

  let updatedEmailSent = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summaryReport && emailSent && emailSent !== '') {
    const emailSentSplit = emailSent.split('  ')
    updatedEmailSent = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(1)}>{emailSentSplit[0]}</a></td><td>&nbsp;</td><td>{emailSentSplit[1]}</td></>
  }

  let updatedOpenEmails = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summaryReport && openEmails && openEmails !== '') {
    const openEmailsSplit = openEmails.split('  ')
    updatedOpenEmails = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(2)}>{openEmailsSplit[0]}</a></td><td>&nbsp;</td><td>{openEmailsSplit[1]}</td></>
  }

  let updatedCickedEmails = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summaryReport && cickedEmails && cickedEmails !== '') {
    const cickedEmailsSplit = cickedEmails.split('  ')
    updatedCickedEmails = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(5)}>{cickedEmailsSplit[0]}</a></td><td>&nbsp;</td><td>{cickedEmailsSplit[1]}</td></>
  }

  let updatedBounceEmails = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summaryReport && bounceEmails && bounceEmails !== '') {
    const bounceEmailsSplit = bounceEmails.split('  ')
    updatedBounceEmails = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(4)}>{bounceEmailsSplit[0]}</a></td><td>&nbsp;</td><td>{bounceEmailsSplit[1]}</td></>
  }

  let updatedUnsubscribEmails = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summaryReport && unsubscribEmails && unsubscribEmails !== '') {
    const updatedUnsubscribEmailsSplit = unsubscribEmails.split('  ')
    updatedUnsubscribEmails = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(3)}>{updatedUnsubscribEmailsSplit[0]}</a></td><td>&nbsp;</td><td>{updatedUnsubscribEmailsSplit[1]}</td></>
  }

  return (
    <>
      <Header />
      <div className='page-content'>
        <Row className='page-header'>
          <div className='desktop' style={{ width: 0, marginRight: 12 }} />
          <div className='logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/CURE_Logo.png' />
            </a>
          </div>
          <div className='div-between-headers desktop' style={{ width: 98 }} />
          <div className='abc-logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/ABC-Michigan-Combined-Logo_v3.png' />
            </a>
          </div>
        </Row>
        <Row className='home report-by-cd'>
          {
            summaryReport && <Card
              bordered={false}
              className='input-card'
              title={<>{showBackButton && <Button className='back-button' icon={<LeftOutlined style={{ fontSize: 16 }} />} onClick={() => handleBack()} shape="default" />}Company - Summary Report</>}
            >
              <Row className='upload-per-colleague'>
                <Row className='row-width'>
                  <table>
                    <tr><th>Company Name: </th><><td>&nbsp;</td><td>{companyName}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                  </table>
                </Row>
                <Row className='row-width'>
                  <table>
                    <tr><th>Employees Enrolled:</th>{updatedEmployeeEnrolled}</tr>
                    <tr><th>Emails Sent:</th>{updatedEmailSent}</tr>
                    <tr><th>Emails Opened: </th>{updatedOpenEmails}</tr>
                    <tr><th>Emails Clicked: </th>{updatedCickedEmails}</tr>
                    <tr><th>Emails Bounced: </th>{updatedBounceEmails}</tr>
                    <tr><th>Emails Unsubscribed: </th>{updatedUnsubscribEmails}</tr>
                  </table>
                </Row>
                <Row className='row-width'>
                  <table>
                    <tr><th>Chapter: </th><><td>&nbsp;</td><td>{chapterName}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>Contact First Name: </th><><td>&nbsp;</td><td>{contactFirstName}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>Contact Last Name: </th><><td>&nbsp;</td><td>{contactLastName}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>Contact Email: </th><><td>&nbsp;</td><td>{contactEmail}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>Contact Phone: </th><><td>&nbsp;</td><td>{contactPhone}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>President First Name: </th><><td>&nbsp;</td><td>{presidentFirstName}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>President Last Name: </th><><td>&nbsp;</td><td>{presidentLastName}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>Address 1: </th><><td>&nbsp;</td><td>{orgAddress1}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>Address 2: </th><><td>&nbsp;</td><td>{orgAddress2}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>City: </th><><td>&nbsp;</td><td>{city}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>State: </th><><td>&nbsp;</td><td>{state}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                    <tr><th>Zip Code: </th><><td>&nbsp;</td><td>{zipCode}</td><td>&nbsp;</td><td>&nbsp;</td></></tr>
                  </table>
                </Row>
              </Row>
              {showSpinner && <Spin className='report-spinner' indicator={antIcon} />}
            </Card>
          }
        </Row>
      </div>
      <Footer />
    </>
  )
}

ReportingByCompanyCd.propTypes = {
  getSumaryReportBySubOrg: PropTypes.func,
  getSumaryReportByCompanyCd: PropTypes.func,
  getSummaryReport: PropTypes.func,
  reportByCompanyCd: PropTypes.array,
  summary: PropTypes.object,
  summaryByOrg: PropTypes.object
}

const mapStateToProps = ({
  report
}) => ({
  summary: report.summary,
  summaryByOrg: report.summaryByOrg,
  reportByCompanyCd: report.reportByCompanyCd
})

const mapDispatchToProps = {
  getSummaryReport,
  getSumaryReportBySubOrg,
  getSumaryReportByCompanyCd
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingByCompanyCd)
