import React from 'react'
import { Layout } from 'antd'

import './Header.css'

const Header = () => {
  return <Layout.Header className='top-header' />
}

Header.propTypes = {}

export default Header
