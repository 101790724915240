import {
  ADD_FORM_BASED_COLLEGUES,
  ADD_MORE_USER,
  GET_UPLOAD_BY_COLLEAGUE,
  SEND_EMAIL,
  UPDATE_ORG_DETAILS
} from '../actionTypes'

const initialState = {
  orgDetail: {
    orgName: '',
    orgPresidentName: '',
    colleagues: [
      {
        firstName: '',
        lastName: '',
        emailAddress: ''
      }
    ]
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_UPLOAD_BY_COLLEAGUE:
      return {
        ...state,
        ...action.payload
      }
    case ADD_MORE_USER:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_ORG_DETAILS:
      return {
        ...state,
        ...action.payload
      }
    case ADD_FORM_BASED_COLLEGUES:
      return {
        ...state,
        ...action.payload
      }
    case SEND_EMAIL:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
