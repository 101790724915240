import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select, Typography } from 'antd'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { connect } from 'react-redux'

import {
  getCompanyChapters,
  updateCompanyUserDetail,
  updateCompanyDetail
} from '~actions'

import './CompanyInfo.css'

const { Text } = Typography
const { Option } = Select

const CompanyInfo = ({
  chapters,
  getCompanyChapters,
  updateCompanyDetail,
  updateCompanyUserDetail,
  users,
  companyDetail
}) => {
  const [orgName, setCompanyName] = useState('')
  const [orgAddress1, setAddress1] = useState('')
  const [orgAddress2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('MI')
  const [zipCode, setZipcode] = useState('')
  const [deputyFirstName, setDeputyFirstName] = useState('')
  const [deputyLastName, setDeputyLastName] = useState('')
  const [deputyTitle, setDeputyTitle] = useState('')
  const [deputyEmailAddress, setEmailAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [orgPresidentFirstName, setPresidentFirstName] = useState('')
  const [orgPresidentLastName, setPresidentLastName] = useState('')
  const [selectedChapter, setSelectedChapter] = useState()
  const [emailErrorValidation, setEmailErrorValidation] = useState()
  const [companyChapters, setCompanyChapters] = useState(null)

  useEffect(() => {
    if (companyDetail) {
      setCompanyName(companyDetail.orgName || '')
      setAddress1(companyDetail.orgAddress1 || '')
      setAddress2(companyDetail.orgAddress2 || '')
      setCity(companyDetail.city || '')
      setState(companyDetail.state || 'MI')
      setZipcode(companyDetail.zipCode || '')
      setDeputyFirstName(companyDetail.deputyFirstName || '')
      setDeputyLastName(companyDetail.deputyLastName || '')
      setDeputyTitle(companyDetail.deputyTitle || '')
      setEmailAddress(companyDetail.deputyEmailAddress || '')
      setPhoneNumber(companyDetail.phoneNumber || '')
      setPresidentFirstName(companyDetail.orgPresidentFirstName || '')
      setPresidentLastName(companyDetail.orgPresidentLastName || '')
      setSelectedChapter(companyDetail.chapter)
    }
  }, [companyDetail])

  useEffect(() => {
    if (!companyChapters) {
      getCompanyChapters()
    }
  }, [companyChapters, getCompanyChapters])

  useEffect(() => {
    if (chapters) {
      setCompanyChapters(chapters)
    }
  }, [chapters])

  const handleOnBlur = (event) => {
    const cloedCompanyDetail = { ...companyDetail }
    cloedCompanyDetail[event.target.name] = event.target.value
    if (event.target.name === 'deputyEmailAddress') {
      validateEmail(deputyEmailAddress)
    }
    updateCompanyDetail(cloedCompanyDetail)
    updateCompanyUserDetail(users, cloedCompanyDetail, false)
  }

  const validateEmail = (email) => {
    const regEx = /\S+@\S+\.\S+/
    let validationResponse = null
    if (email.length > 0) {
      if (regEx.test(email)) {
        validationResponse = null
      } else {
        validationResponse = 'Email Address is invalid'
      }
    }

    setEmailErrorValidation(validationResponse)
  }

  const handleOnPhoneBlur = (event) => {
    const cloedCompanyDetail = { ...companyDetail }
    cloedCompanyDetail.phoneNumber = event.target.value
      .replace(/[^\w\s]/gi, '')
      .replace(' ', '')
    updateCompanyDetail(cloedCompanyDetail)
  }

  const handleOnChaptereBlur = (value) => {
    const cloedCompanyDetail = { ...companyDetail }
    cloedCompanyDetail.chapter = value
    cloedCompanyDetail.companyChapterId = value
    updateCompanyDetail(cloedCompanyDetail)
  }

  const handleOnPhoneChange = event => {
    const phoneNumber = event.target.value.replace(/[^\w\s]/gi, '').replace(' ', '')
    setPhoneNumber(phoneNumber)

    if (phoneNumber.replace(/_/g, '').length === 10) {
      const clonedCompanyDetail = { ...companyDetail }
      clonedCompanyDetail.phoneNumber = phoneNumber
      updateCompanyDetail(clonedCompanyDetail)
    }
  }
  return (
    <Row className='company-info'>
      <Text className='title-text'>Step 1 - Enter Company Information</Text>
      <Col span={24}>
        <Form
          className='user-form'
          colon={false}
          labelCol={{
            span: 8
          }}
          wrapperCol={{
            span: 16
          }}
        >
          <Form.Item className='form-with-label' label='ABC Michigan Chapter*'>
            <Select
              onChange={handleOnChaptereBlur}
              placeholder='Select a Chapter'
              value={selectedChapter}
            >
              {companyChapters &&
                companyChapters.map((chapter) => (
                  <Option key={chapter.id} value={chapter.value}>
                    {chapter.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item className='form-with-label' label='Company Name*'>
            <Input
              autoComplete='off'
              name='orgName'
              onBlur={handleOnBlur}
              onChange={(event) => setCompanyName(event.target.value)}
              placeholder='Company Name'
              value={orgName}
            />
          </Form.Item>
          <Form.Item className='form-with-label' label='Address*'>
            <Input
              autoComplete='off'
              className='margin-bottom-10'
              name='orgAddress1'
              onBlur={handleOnBlur}
              onChange={(event) => setAddress1(event.target.value)}
              placeholder='Address Line 1'
              value={orgAddress1}
            />
          </Form.Item>
          <Form.Item className='form-without-label' label=' '>
            <Row gutter={[0, 8]} justify='start'>
              <Col span={24}>
                <Row gutter={[4, 4]}>
                  <Col span={24}>
                    <Form.Item>
                      <Input
                        autoComplete='off'
                        name='orgAddress2'
                        onBlur={handleOnBlur}
                        onChange={(event) => setAddress2(event.target.value)}
                        placeholder='Address Line 2'
                        value={orgAddress2}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[4, 4]}>
                  <Col lg={12} span={12} xl={12} xs={24}>
                    <Form.Item className='mobile-bottom-0'>
                      <Input
                        autoComplete='off'
                        name='city'
                        onBlur={handleOnBlur}
                        onChange={(event) => setCity(event.target.value)}
                        placeholder='City'
                        value={city}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} span={6} xl={6} xs={24}>
                    <Form.Item className='mobile-bottom-0'>
                      <Input
                        disabled
                        name='state'
                        placeholder='Email'
                        value={state}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} span={6} xl={6} xs={24}>
                    <Form.Item className='mobile-remove-bottom mobile-bottom-0'>
                      <Input
                        autoComplete='off'
                        maxLength={5}
                        name='zipCode'
                        onBlur={handleOnBlur}
                        onChange={(event) => {
                          const value = event.target.value.replace(/\D/g, '')
                          setZipcode(value)
                        }}
                        placeholder='Zip Code'
                        value={zipCode}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item className='form-with-label' label='Your Name*'>
            <Row>
              <Col span={24}>
                <Row gutter={[4, 4]}>
                  <Col lg={12} span={12} xl={12} xs={24}>
                    <Form.Item
                      className='company-employee-first-name'
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        autoComplete='off'
                        name='deputyFirstName'
                        onBlur={handleOnBlur}
                        onChange={(event) =>
                          setDeputyFirstName(event.target.value)
                        }
                        placeholder='First Name'
                        value={deputyFirstName}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} span={12} xl={12} xs={24}>
                    <Form.Item
                      className='company-employee-last-name'
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        autoComplete='off'
                        name='deputyLastName'
                        onBlur={handleOnBlur}
                        onChange={(event) =>
                          setDeputyLastName(event.target.value)
                        }
                        placeholder='Last Name'
                        value={deputyLastName}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item className='form-with-label' label='Your Title*'>
            <Input
              autoComplete='off'
              name='deputyTitle'
              onBlur={handleOnBlur}
              onChange={(event) => setDeputyTitle(event.target.value)}
              placeholder='Title'
              value={deputyTitle}
            />
          </Form.Item>
          <Form.Item className='form-with-label' label='Your Email*'>
            <Input
              autoComplete='off'
              name='deputyEmailAddress'
              onBlur={handleOnBlur}
              onChange={(event) => setEmailAddress(event.target.value)}
              placeholder='Email'
              value={deputyEmailAddress}
            />
            {emailErrorValidation && (
              <span className='error-message'>{emailErrorValidation}</span>
            )}
          </Form.Item>
          <Form.Item
            className='form-with-label'
            label='Your Phone*'
            style={{ marginBottom: 8 }}
          >
            <InputMask
              className='phoneNumber'
              mask='(999) 999-9999'
              onBlur={handleOnPhoneBlur}
              onChange={(event) => handleOnPhoneChange(event)}
              placeholder='Phone Number'
              value={phoneNumber}
            />
          </Form.Item>
          <Form.Item className='desktop' label=' ' style={{ marginBottom: 4 }}>
            <Col lg={14} span={14} xl={14} />
            <Col lg={24} span={24} xl={24} xs={24}>
              <Text style={{ display: 'block', marginBottom: 8 }}>
                Note: If you would like the President/Owner name included in the
                welcome email to your employees, please add it here.
              </Text>
            </Col>
          </Form.Item>
          <Form.Item className='form-with-label' label='President/Owner Name'>
            <Text className='mobile' style={{ marginBottom: 8 }}>
              Note: If you would like the President/Owner name included in the
              welcome email to your employees, please add it here.
            </Text>
            <Row>
              <Col span={24}>
                <Row gutter={[4, 4]}>
                  <Col lg={12} span={12} xl={12} xs={24}>
                    <Form.Item
                      className='company-owner-first-name'
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        autoComplete='off'
                        name='orgPresidentFirstName'
                        onBlur={handleOnBlur}
                        onChange={(event) =>
                          setPresidentFirstName(event.target.value)
                        }
                        placeholder='First Name'
                        value={orgPresidentFirstName}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} span={12} xl={12} xs={24}>
                    <Form.Item
                      className='company-owner-last-name'
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        autoComplete='off'
                        name='orgPresidentLastName'
                        onBlur={handleOnBlur}
                        onChange={(event) =>
                          setPresidentLastName(event.target.value)
                        }
                        placeholder='Last Name'
                        value={orgPresidentLastName}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

CompanyInfo.propTypes = {
  chapters: PropTypes.array,
  getCompanyChapters: PropTypes.func,
  companyDetail: PropTypes.object,
  updateCompanyDetail: PropTypes.func,
  updateCompanyUserDetail: PropTypes.func,
  users: PropTypes.array
}

const mapStateToProps = ({ companyInfo, contactInfo }) => ({
  chapters: companyInfo.chapters,
  companyDetail: companyInfo.companyDetail,
  users: contactInfo.users
})

const mapDispatchToProps = {
  getCompanyChapters,
  updateCompanyDetail,
  updateCompanyUserDetail
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo)
