import {
  SUMMARY_REPORT,
  SUMMARY_REPORT_BYSUB_ORG,
  SUMMARY_REPORT_BY_SUB_ORG_TYPE,
  SUMMARY_REPORT_BY_SUB_ORG_COMPANY_CD
} from '../actionTypes'

const initialState = {

}

export default function (state = initialState, action) {
  switch (action.type) {
    case SUMMARY_REPORT:
      return {
        ...state,
        ...action.payload
      }
    case SUMMARY_REPORT_BYSUB_ORG:
      return {
        ...state,
        ...action.payload
      }
    case SUMMARY_REPORT_BY_SUB_ORG_TYPE:
      return {
        ...state,
        ...action.payload
      }
    case SUMMARY_REPORT_BY_SUB_ORG_COMPANY_CD:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
