import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Spin, Row } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSummaryReport, getSumaryReportBySubOrg } from '~actions'
import { Header, Footer } from '~components'

import './Reporting.css'

const antIcon = <LoadingOutlined spin style={{ fontSize: 48 }} />

const Reporting = ({ getSummaryReport, summary, summaryByOrg, reportByType }) => {
  const { subOrg } = useParams()
  const [showSpinner, setShowSpinner] = useState(false)
  const [reportTypeResponse, setReportTypeResponse] = useState(null)

  useEffect(() => {
    if (subOrg) {
      setShowSpinner(true)
      getSummaryReport(subOrg)
    }
  }, [getSummaryReport, subOrg])

  useEffect(() => {
    if (reportByType) {
      setShowSpinner(false)
      setReportTypeResponse(reportByType)
    }
  }, [reportByType])

  useEffect(() => {
    if (summaryByOrg) {
      setShowSpinner(false)
    }
  }, [summaryByOrg])

  useEffect(() => {
    if (summary) {
      setShowSpinner(false)
    }
  }, [summary])

  const handleClick = reportType => {
    setShowSpinner(true)
    window.location = `/admin/abc/summary-report/${reportType}`
  }

  const {
    bounceEmails,
    cickedEmails,
    emailSent,
    openEmails,
    totalCompanyEnrolled,
    unsubscribEmails
  } = summary ?? {}

  const companyEnrolled = totalCompanyEnrolled > 0 ? <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(0)}>{totalCompanyEnrolled}</a></td><td>&nbsp;</td><td>&nbsp;</td></> : <><td>&nbsp;</td><td>n/a</td><td>&nbsp;</td><td>&nbsp;</td></>
  let updatedEmailSent = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summary && emailSent !== '0  (0.0%)') {
    const emailSentSplit = emailSent.split('  ')
    updatedEmailSent = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(1)}>{emailSentSplit[0]}</a></td><td>&nbsp;</td><td>{emailSentSplit[1]}</td></>
  }

  let updatedOpenEmails = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summary && openEmails !== '0  (0.0%)') {
    const openEmailsSplit = openEmails.split('  ')
    updatedOpenEmails = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(2)}>{openEmailsSplit[0]}</a></td><td>&nbsp;</td><td>{openEmailsSplit[1]}</td></>
  }

  let updatedCickedEmails = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summary && cickedEmails !== '0  (0.0%)') {
    const cickedEmailsSplit = cickedEmails.split('  ')
    updatedCickedEmails = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(5)}>{cickedEmailsSplit[0]}</a></td><td>&nbsp;</td><td>{cickedEmailsSplit[1]}</td></>
  }

  let updatedBounceEmails = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summary && bounceEmails !== '0  (0.0%)') {
    const bounceEmailsSplit = bounceEmails.split('  ')
    updatedBounceEmails = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(4)}>{bounceEmailsSplit[0]}</a></td><td>&nbsp;</td><td>{bounceEmailsSplit[1]}</td></>
  }

  let updatedUnsubscribEmails = <><td>&nbsp;</td><td className='text-align'>0</td><td>&nbsp;</td><td>&nbsp;</td></>
  if (summary && unsubscribEmails !== '0  (0.0%)') {
    const updatedUnsubscribEmailsSplit = unsubscribEmails.split('  ')
    updatedUnsubscribEmails = <><td>&nbsp;</td><td className='text-align'><a className='report-link' onClick={() => handleClick(3)}>{updatedUnsubscribEmailsSplit[0]}</a></td><td>&nbsp;</td><td>{updatedUnsubscribEmailsSplit[1]}</td></>
  }

  return (
    <>
      <Header />
      <div className='page-content'>
        <Row className='page-header'>
          <div className='desktop' style={{ width: 0, marginRight: 12 }} />
          <div className='logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/CURE_Logo.png' />
            </a>
          </div>
          <div className='div-between-headers desktop' style={{ width: 98 }} />
          <div className='abc-logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/ABC-Michigan-Combined-Logo_v3.png' />
            </a>
          </div>
        </Row>
        <Row className='home reporting'>
          <Card
            bordered={false}
            className='input-card'
            title='Summary Report'
          >
            { summary && !reportTypeResponse && <Row className='upload-per-colleague'>
              <table>
                <tr><th>Member Companies Enrolled: </th>{companyEnrolled}</tr>
                <tr><th>Emails Sent: </th>{updatedEmailSent}</tr>
                <tr><th>Emails Opened: </th>{updatedOpenEmails}</tr>
                <tr><th>Emails Clicked: </th>{updatedCickedEmails}</tr>
                <tr><th>Emails Bounced: </th>{updatedBounceEmails}</tr>
                <tr><th>Emails Unsubscribed: </th>{updatedUnsubscribEmails}</tr>
              </table>
            </Row>}
            {showSpinner && <Spin className='report-spinner' indicator={antIcon} />}
          </Card>
        </Row>
      </div>
      <Footer />
    </>
  )
}

Reporting.propTypes = {
  getSumaryReportBySubOrg: PropTypes.func,
  getSummaryReport: PropTypes.func,
  reportByType: PropTypes.object,
  summary: PropTypes.object,
  summaryByOrg: PropTypes.object
}

const mapStateToProps = ({
  report
}) => ({
  summary: report.summary,
  summaryByOrg: report.summaryByOrg,
  reportByType: report.reportByType
})

const mapDispatchToProps = {
  getSummaryReport,
  getSumaryReportBySubOrg
}

export default connect(mapStateToProps, mapDispatchToProps)(Reporting)
