import React from 'react'
import { Button, Row, Table } from 'antd'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { useParams } from 'react-router-dom'
import { calculateColumnsWidth } from './DynamicColumnHelper'

import './ReportTable.css'

const ReportTable = ({ dataSource }) => {
  const { type } = useParams()

  const maxWidthPerCell = 200
  const tableHeight = 500

  const handleClick = record => {
    window.location = `/admin/abc/summary-report/${record.companyCd}/0`
  }

  const contactColumns = [
    {
      title: 'Chapter',
      label: 'Chapter',
      dataIndex: 'chapterName',
      key: 'chapterName'
    },
    {
      title: 'Company',
      label: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <a onClick={() => handleClick(record)}>{text}</a>
    },
    {
      title: 'Contact First Name',
      label: 'Contact First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => <>{record.contactFirstName || record.firstName}</>
    },
    {
      title: 'Contact Last Name',
      label: 'Contact Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, record) => <>{record.contactLastName || record.lastName}</>
    },
    {
      title: 'Contact Email',
      label: 'Contact Email',
      dataIndex: 'contactEmail',
      key: 'contactEmail'
    },
    {
      title: 'Contact Phone',
      label: 'Contact Phone',
      dataIndex: 'contactPhone',
      key: 'contactPhone'
    },
    {
      title: 'Date/Time',
      label: 'Date/Time',
      dataIndex: 'dateSent',
      key: 'dateSent'
    }
  ]

  const employeeColumns = [
    {
      title: 'Chapter',
      label: 'Chapter',
      dataIndex: 'chapterName',
      key: 'chapterName'
    },
    {
      title: 'Company',
      label: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <a onClick={() => handleClick(record)}>{text}</a>
    },
    {
      title: 'Employee First Name',
      label: 'Employee First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => <>{record.contactFirstName || record.firstName}</>
    },
    {
      title: 'Employee Last Name',
      label: 'Employee Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, record) => <>{record.contactLastName || record.lastName}</>
    },
    {
      title: 'Employee Email',
      label: 'Employee Email',
      dataIndex: 'emailAddress',
      key: 'emailAddress'
    },
    {
      title: 'Date/Time',
      label: 'Date/Time',
      dataIndex: 'dateEmailSent',
      key: 'dateEmailSent'
    }
  ]

  const updatedColumns = type === '0' ? contactColumns : employeeColumns

  const dataTable = dataSource && calculateColumnsWidth(updatedColumns, dataSource, maxWidthPerCell)

  const updatedDataSource = dataSource.map(source => ({
    ...source,
    firstName: source.contactFirstName || source.firstName,
    lastName: source.contactLastName || source.lastName
  }))

  return <>
    <Row className='report-by-type'>
      <Button>
        <CSVLink
          data={updatedDataSource}
          filename='report'
          headers={updatedColumns}
        >
          <img src="https://assets.cure.com/abc/img/excel.png" />
        </CSVLink>
      </Button>
      <Table
        columns={updatedColumns}
        dataSource={dataSource}
        height={tableHeight}
        pagination={{ pageSize: 50 }}
        scroll={{ x: dataTable.tableWidth, y: tableHeight }}
      />
    </Row>
  </>
}

ReportTable.propTypes = {
  dataSource: PropTypes.array
}

export default ReportTable
