import { GET_HOME_OPTIONS, UPDATE_HOME_OPTIONS } from '../actionTypes'

const initialState = {
  currentStep: 0,
  selectedOption: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HOME_OPTIONS:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_HOME_OPTIONS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
