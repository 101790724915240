import React, { useEffect, useState } from 'react'
import { PaperClipOutlined, CloudUploadOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  message,
  Form,
  Radio,
  Row,
  Space,
  Typography,
  Upload
} from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  deleteUserContact,
  updateCompanyUserDetail,
  addNewUserContact,
  updateUsers,
  uploadFileToStore,
  updateOptionSelected
} from '~actions'
import { EditableTable } from '~components'
import { DeleteIcon } from '../../components/SVGComponent/SVGComponent'

import './EmployeeInfo.css'

const { Dragger } = Upload

const FILE_UPLOAD_SIZE = 5

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstname'
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastname'
  },
  {
    title: 'Email',
    dataIndex: 'emailAddress',
    key: 'emailAddress'
  }
]

const userObj = {
  firstName: '',
  lastName: '',
  emailAddress: ''
}

const { Text } = Typography

const EmployeeInfo = ({
  companyDetail,
  updateCompanyUserDetail,
  selectedOption,
  updateOptionSelected,
  uploadedFile = {},
  uploadFileToStore,
  users,
  isUsersEmpty,
  deleteUserContact,
  addNewUserContact
}) => {
  const [fileList, setFileList] = useState([])
  const [initialLoad, setInitialLoad] = useState(true)

  useEffect(() => {
    if (uploadedFile && uploadedFile.file) {
      setFileList(uploadedFile.file)
    }
  }, [uploadedFile])

  useEffect(() => {
    if (companyDetail && isUsersEmpty && initialLoad) {
      updateCompanyUserDetail(users, companyDetail, false)
      setInitialLoad(false)
    }
  }, [
    companyDetail,
    updateCompanyUserDetail,
    users,
    isUsersEmpty,
    initialLoad
  ])

  const [usersList, setUsersList] = useState([])

  useEffect(() => {
    if (users) {
      setUsersList(users)
    }
  }, [users])

  const addUser = () => {
    const clonedUsers = [...users]
    clonedUsers.push({ ...userObj })
    addNewUserContact(clonedUsers)
  }
  const deleteUser = (index) => {
    const clonedUsers = [...users]
    clonedUsers.splice(index, 1)
    deleteUserContact(clonedUsers)
  }

  const validatedUsers = (users) => {
    const regEx = /\S+@\S+\.\S+/

    return users.map((user) => {
      if (!user.firstName && !user.lastName && !regEx.test(user.emailAddress)) {
        user.valid = true
      } else {
        user.valid = false
      }
      if (user.firstName && user.lastName && regEx.test(user.emailAddress)) {
        user.valid = true
      } else {
        user.valid = false
      }
      return user
    })
  }

  const onUserTableUpdate = (event, index) => {
    const clonedUsers = [...usersList]
    clonedUsers[index][event.target.name] = event.target.value
    const updatedUsers = validatedUsers(clonedUsers)
    updateUsers(updatedUsers)
  }

  const props = {
    name: 'file',
    accept: '.csv,.xlsx,.xls',
    beforeUpload: (file) => {
      const isLt2M = file.size / 1024 / 1024 < FILE_UPLOAD_SIZE
      if (!isLt2M) {
        return message.error(
          `Document must smaller than ${FILE_UPLOAD_SIZE}MB!`
        )
      }
      setFileList([file])
      uploadFileToStore([file])
    },
    fileList,
    maxCount: 1,
    onRemove: () => {
      setFileList([])
      uploadFileToStore([])
    }
  }

  return (
    <Row className='employee-info'>
      <Text className='title-text'>Step 2 - Enter Employee Information</Text>
      <Text style={{ width: '100%', marginBottom: 12 }}>
        Please choose your preference:
      </Text>
      <Col span={24}>
        <Form
          className='user-form'
          colon={false}
          labelCol={{
            span: 8
          }}
          wrapperCol={{
            span: 14
          }}
        >
          <Radio.Group
            onChange={(e) => updateOptionSelected(e.target.value)}
            value={selectedOption}
          >
            <Space direction='vertical'>
              <Radio value={1}>Type or Paste Contacts</Radio>
              <Radio value={2}>
                Upload a File (Acceptable formats: .csv, .xlsx, .xls)
              </Radio>
            </Space>
          </Radio.Group>
        </Form>
      </Col>
      <Text style={{ width: '100%', marginTop: 12, marginBottom: 0 }}>
        Please note: Your employee email addresses are strictly confidential and
        NOT shared with any outside organization.
      </Text>
      {selectedOption === 2 && (
        <Row className='bulk-upload' style={{ width: '100%' }}>
          <Dragger {...props}>
            <p className='ant-upload-drag-icon'>
              <CloudUploadOutlined />
            </p>
            <p className='ant-upload-text'>
              Drag and drop your file here
              <br />
              or <span className='underline'>browse</span> your computer.
            </p>
            <p className='ant-upload-text'>
              Acceptable formats: .csv, .xlsx, .xls
            </p>
            <p className='ant-upload-text'>
              Please follow the example below with &quot;First Name, Last Name
              and Email&quot; in that order.
            </p>
            <p className='ant-upload-text'>Example:</p>
            <img src='https://assets.cure.com/abc/img/CapClient-Upload.png' />
          </Dragger>
          {fileList.map((file, index) => (
            <div
              key={index}
              className='employee-info-dragger-file-display-div'
              style={{ marginTop: 12 }}
            >
              <PaperClipOutlined style={{ display: 'initial', marginRight: 6 }} />
              {file.name}{' '}
              <Button
                className='delete-item'
                icon={<DeleteIcon />}
                onClick={() => {
                  setFileList([])
                  uploadFileToStore([])
                }}
                shape='circle'
                size='large'
                type='primary'
              />
            </div>
          ))}
        </Row>
      )}
      {selectedOption === 1 && (
        <Row className='company-info' style={{ width: '100%' }}>
          <Col className='employee-form-col' span={24}>
            <EditableTable
              addNewUserContact={addUser}
              columns={columns}
              deleteUserContact={deleteUser}
              onUserTableUpdate={onUserTableUpdate}
              users={usersList}
            />
          </Col>
        </Row>
      )}
    </Row>
  )
}

EmployeeInfo.propTypes = {
  updateOptionSelected: PropTypes.func,
  selectedOption: PropTypes.number,
  uploadedFile: PropTypes.object,
  uploadFileToStore: PropTypes.func,
  users: PropTypes.array,
  deleteUserContact: PropTypes.func,
  addNewUserContact: PropTypes.func,
  companyDetail: PropTypes.object,
  updateCompanyUserDetail: PropTypes.func,
  isUsersEmpty: PropTypes.bool
}

const mapStateToProps = ({
  companyInfo,
  employeeInfo,
  contactInfo,
  bulkUpload
}) => ({
  selectedOption: employeeInfo.selectedOption,
  uploadedFile: bulkUpload.uploadedFile,
  users: contactInfo.users,
  isUsersEmpty: contactInfo.isUsersEmpty,
  companyDetail: companyInfo.companyDetail
})

const mapDispatchToProps = {
  uploadFileToStore,
  updateOptionSelected,
  deleteUserContact,
  addNewUserContact,
  updateCompanyUserDetail,
  updateUsers
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeInfo)
