import { GET_HOME_OPTIONS, UPDATE_HOME_OPTIONS } from '../actionTypes'

export const getHomeSelectedOption = (params) => async (dispatch) => {
  dispatch({
    type: GET_HOME_OPTIONS,
    payload: {
      params
    }
  })
}

export const updateOptionSelected = (selectedOption) => async (dispatch) => {
  dispatch({
    type: UPDATE_HOME_OPTIONS,
    payload: {
      selectedOption
    }
  })
}
