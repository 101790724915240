import React from 'react'
import { Button, Card, Col, Row, Typography, Steps } from 'antd'
import { Header, Footer } from '~components'

import './Enrollment.css'

const { Link, Text } = Typography
const { Step } = Steps

const Enrollment = () => (
  <>
    <Header />
    <div className='page-content'>
      <Row className='page-header'>
        <div className='desktop' style={{ width: 0, marginRight: 12 }} />
        <div className='logo-holder'>
          <a href='/'>
            <img src='https://assets.cure.com/abc/img/CURE_Logo.png' />
          </a>
        </div>
        <div className='div-between-headers desktop' style={{ width: 98 }} />
        <div className='abc-logo-holder'>
          <a href='/'>
            <img src='https://assets.cure.com/abc/img/ABC-Michigan-Combined-Logo_v3.png' />
          </a>
        </div>
      </Row>
      <Row className='home'>
        <Card
          bordered={false}
          className='input-card'
          title='ABC Michigan Member Enrollment'
        >
          <Row className='home'>
            <Text className='title-text'>Welcome ABC Michigan Members!</Text>
            <Text>
              You’re ready to start enrolling your company and employees in the
              CURE Affiliate Program (CAP). The rest is as easy as 1 – 2 – 3.
            </Text>
            <Text className='sub-text'>Enrollment Process Overview: </Text>
            <Steps
              className='process-steps'
              current={-1}
              direction='vertical'
              size='small'
            >
              <Step className='step-1' title='Enter Company Information' />
              <Step
                className='step-2'
                title='Enter Employee Contact Information (First Name, Last Name, Email)'
              />
              <Step className='review' title='Review and Submit' />
            </Steps>
            <Text className='bottom-text sub-text'>
              Once enrolled, your employees will receive a personalized email
              from CURE inviting them to get a custom ABC Michigan quote that
              includes a 10% discount for employees of ABC Michigan member
              companies. This email will include a special link to get their
              custom quote online along with our phone number if they prefer to
              speak with a Customer Relations representative.
            </Text>
            <Row className='button-container'>
              <Button
                className='prev-button'
                onClick={() => {
                  window.location.href = '/company-info'
                }}
                type='primary'
              >
                START NOW
              </Button>
            </Row>
            <Row
              style={{
                width: '100%',
                background: 'white',
                paddingTop: 12,
                paddingBottom: 12
              }}
            >
              <Col span={24} style={{ textAlign: 'center', fontSize: 18 }}>
                Questions?{' '}
                <Link
                  className='question-link'
                  href='mailto:ABCMichigan@cure.com'
                >
                  ABCMichigan@cure.com
                </Link>
              </Col>
            </Row>
          </Row>
        </Card>
      </Row>
    </div>
    <Footer />
  </>
)

Enrollment.propTypes = {}

export default Enrollment
