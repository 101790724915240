import React, { useEffect, useState } from 'react'
import { LoadingOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Card, Select, Spin, Row, Typography } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSummaryReport, getSumaryReportBySubOrg, getSumaryReportByType } from '~actions'
import { ReportTable, Header, Footer } from '~components'

import './Report.css'

const { Option } = Select
const { Text } = Typography
const antIcon = <LoadingOutlined spin style={{ fontSize: 48 }} />

const Report = ({ getSummaryReport, summary, summaryByOrg, getSumaryReportBySubOrg, getSumaryReportByType, reportByType }) => {
  const { subOrg } = useParams()
  const [summaryReport, setSummaryReport] = useState(null)
  const [subOrgSummary, setSubOrgSummary] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const [listOfSubOrgs, setListOfSubOrgs] = useState(null)
  const [selectedSubOrg, setSelectedSubOrg] = useState(null)
  const [reportTypeResponse, setReportTypeResponse] = useState(null)
  const [showBackButton, setShowBackButton] = useState(null)

  useEffect(() => {
    if (subOrg) {
      setShowSpinner(true)
      getSummaryReport(subOrg)
    }
  }, [getSummaryReport, subOrg])

  useEffect(() => {
    if (reportByType) {
      setShowSpinner(false)
      setShowBackButton(true)
      setReportTypeResponse(reportByType)
    }
  }, [reportByType])

  useEffect(() => {
    if (summaryByOrg) {
      setShowSpinner(false)
      const [report] = Object.values(summaryByOrg)
      setSubOrgSummary(report)
    }
  }, [summaryByOrg])

  useEffect(() => {
    if (summary) {
      setShowSpinner(false)
      const [report] = Object.values(summary)
      const listOfSubOrgs = Object.keys(summary)
      setListOfSubOrgs(listOfSubOrgs)
      setSummaryReport(report)
    }
  }, [summary])

  const handleChange = (value) => {
    setReportTypeResponse(null)
    setSelectedSubOrg(value)
    setShowBackButton(false)
    setShowSpinner(true)
    getSumaryReportBySubOrg(value)
  }

  const handleClick = reportType => {
    setShowSpinner(true)
    getSumaryReportByType(selectedSubOrg, reportType)
  }

  const handleBack = () => {
    setSelectedSubOrg(null)
    setReportTypeResponse(null)
    setShowBackButton(false)
  }

  return (
    <>
      <Header />
      <div className='page-content'>
        <Row className='page-header'>
          <div className='desktop' style={{ width: 0, marginRight: 12 }} />
          <div className='logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/CURE_Logo.png' />
            </a>
          </div>
          <div className='div-between-headers desktop' style={{ width: 98 }} />
          <div className='abc-logo-holder'>
            <a href='/'>
              <img src='https://assets.cure.com/abc/img/ABC-Michigan-Combined-Logo_v3.png' />
            </a>
          </div>
        </Row>
        <Row className='home'>
          <Card
            bordered={false}
            className='input-card'
            title={<>{showBackButton && <Button className='back-button' icon={<LeftOutlined style={{ fontSize: 16 }} />} onClick={() => handleBack()} shape="default" />}Summary Report</>}
          >
            <Row className='upload-per-colleague'>
              { summaryReport && <Row className='row-width'>
                <Text className='label'>Select the Sub Org Name: </Text>
                <Select className='summary-list' defaultValue={selectedSubOrg} onChange={handleChange}>
                  {
                    listOfSubOrgs.map((item, index) => <Option key={index} value={item}>{item}</Option>)
                  }
                </Select>
              </Row> }
              { !reportTypeResponse && subOrgSummary && <><Row className='row-width'>
                {/* <Text className='label'>Total # of mails sent: </Text>
                <Text className='value'>
                  {
                    subOrgSummary.totalCount > 0 ? <a className='report-link' onClick={() => handleClick('tms')}>{subOrgSummary.totalCount}</a>
                      : subOrgSummary.totalCount
                  }
                </Text>
              </Row>
              <Row className='row-width'>
                <Text className='label'>Open rate % (based on the # of mails successfully delivered): </Text>
                <Text className='value'><a className='report-link' onClick={() => handleClick('clicked')}>{subOrgSummary.sucessfullyDelivered}</a></Text>
              </Row>
              <Row className='row-width'> */}
                {/* <Text className='label'>Click rate % (based on the # of opens): </Text>
                <Text className='value'>
                  {
                    subOrgSummary.openEmails > 0 ? <a className='report-link' onClick={() => handleClick('opened')}>{subOrgSummary.openEmails}</a>
                      : subOrgSummary.openEmails
                  }
                </Text>
              </Row>
              <Row className='row-width'>
                <Text className='label'>Total # of bounces: </Text>
                <Text className='value'>
                  {
                    subOrgSummary.bounceEmails > 0 ? <a className='report-link' onClick={() => handleClick('bounced')}>{subOrgSummary.bounceEmails}</a>
                      : subOrgSummary.bounceEmails
                  }
                </Text>
              </Row>
              <Row className='row-width'>
                <Text className='label'>Total # of unsubscribes: </Text>
                <Text className='value'>
                  {
                    subOrgSummary.unsubscribEmails > 0 ? <a className='report-link' onClick={() => handleClick('unsubscribe')}>{subOrgSummary.unsubscribEmails}</a>
                      : subOrgSummary.unsubscribEmails
                  }
                </Text> */}
                <div className="row">
                  <div className="column1">
                    <Typography className='label'>Total # of mails sent: </Typography>
                    <Typography className='label'>Open rate % (based on the # of mails successfully delivered): </Typography>
                    <Typography className='label'>Click rate % (based on the # of opens): </Typography>
                    <Typography className='label'>Total # of bounces: </Typography>
                    <Typography className='label'>Total # of unsubscribes: </Typography>
                  </div>
                  <div className="column2">
                    <Typography className='value'>
                      {
                        subOrgSummary.totalCount > 0 ? <a className='report-link' onClick={() => handleClick('tms')}>{subOrgSummary.totalCount}</a>
                          : subOrgSummary.totalCount
                      }
                    </Typography>
                    <Typography className='value'>
                      {
                        subOrgSummary.sucessfullyDelivered > 0 ? <a className='report-link' onClick={() => handleClick('clicked')}>{subOrgSummary.sucessfullyDelivered}</a>
                          : subOrgSummary.sucessfullyDelivered
                      }
                    </Typography>
                    <Typography className='value'>
                      {
                        subOrgSummary.openEmails > 0 ? <a className='report-link' onClick={() => handleClick('opened')}>{subOrgSummary.openEmails}</a>
                          : subOrgSummary.openEmails
                      }
                    </Typography>
                    <Typography className='value'>
                      {
                        subOrgSummary.bounceEmails > 0 ? <a className='report-link' onClick={() => handleClick('bounced')}>{subOrgSummary.bounceEmails}</a>
                          : subOrgSummary.bounceEmails
                      }
                    </Typography>
                    <Typography className='value'>
                      {
                        subOrgSummary.unsubscribEmails > 0 ? <a className='report-link' onClick={() => handleClick('unsubscribe')}>{subOrgSummary.unsubscribEmails}</a>
                          : subOrgSummary.unsubscribEmails
                      }
                    </Typography>
                  </div>
                </div>
              </Row>
              </>}
              {
                reportTypeResponse && <ReportTable dataSource={reportByType} />
              }
            </Row>
            {showSpinner && <Spin className='report-spinner' indicator={antIcon} />}
          </Card>
        </Row>
      </div>
      <Footer />
    </>
  )
}

Report.propTypes = {
  getSumaryReportBySubOrg: PropTypes.func,
  getSumaryReportByType: PropTypes.func,
  getSummaryReport: PropTypes.func,
  reportByType: PropTypes.object,
  summary: PropTypes.object,
  summaryByOrg: PropTypes.object
}

const mapStateToProps = ({
  report
}) => ({
  summary: report.summary,
  summaryByOrg: report.summaryByOrg,
  reportByType: report.reportByType
})

const mapDispatchToProps = {
  getSummaryReport,
  getSumaryReportBySubOrg,
  getSumaryReportByType
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)
