import { employeeEndpoint } from '~apiConfig'
import { getApiResponseInJSON } from '~utils'
import {
  DELETE_USER,
  ADD_USER,
  GET_USERS_LIST,
  UPDATE_USERS,
  UPDATE_COMPANY_USER
} from '../actionTypes'

export const getUsersList = (params) => async (dispatch) => {
  dispatch({
    type: GET_USERS_LIST,
    payload: {
      params
    }
  })
}

export const deleteUserContact = (users) => async (dispatch) => {
  dispatch({
    type: DELETE_USER,
    payload: {
      users
    }
  })
}

export const addNewUserContact = (users) => async (dispatch) => {
  dispatch({
    type: ADD_USER,
    payload: {
      users
    }
  })
}

export const updateUsers = (users) => async (dispatch) => {
  dispatch({
    type: UPDATE_USERS,
    payload: {
      users
    }
  })
}

export const updateCompanyUserDetail =
  (users, companyDetail, isUsersEmpty) => async (dispatch) => {
    const clonedUsers = [...users]
    clonedUsers.splice(0, 1, {
      emailAddress: companyDetail.deputyEmailAddress,
      firstName: companyDetail.deputyFirstName,
      lastName: companyDetail.deputyLastName,
      valid: true
    })
    let payload = {
      users: clonedUsers,
      isUsersEmpty: true
    }
    if (companyDetail.deputyEmailAddress) {
      payload = {
        users: clonedUsers,
        isUsersEmpty
      }
    }
    dispatch({
      type: UPDATE_COMPANY_USER,
      payload
    })
  }

export const validateFileUploadOfColleagues =
  (formData) => async (dispatch) => {
    const { method, url } = employeeEndpoint.validateFileUploadOfColleagues
    const apiResponse = await fetch(url, {
      method,
      body: formData
    })
      .then(async (response) => await getApiResponseInJSON(response))
      .catch((error) => console.error(error))

    const { validCollegues, inValidCollegues } = apiResponse
    const validlist = validCollegues.map((collegue) => ({
      ...collegue,
      valid: true
    }))
    const inValidlist = inValidCollegues.map((collegue) => ({
      ...collegue,
      valid: false
    }))

    dispatch({
      type: UPDATE_USERS,
      payload: {
        users: [...validlist, ...inValidlist]
      }
    })
  }
