import React from 'react'
import { Card, Col, Form, Input, Row, Table, Tooltip, Typography } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleStepUpdate } from '~actions'

import './Review.css'

const { Text } = Typography

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName'
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName'
  },
  {
    title: 'Email',
    dataIndex: 'emailAddress'
  }
]

const formatPhoneNumber = (phoneNumber) => {
  const USNumber = phoneNumber.match(/(\d{3})(\d{3})(\d{4})/)
  return USNumber ? `(${USNumber?.[1]}) ${USNumber?.[2]}-${USNumber?.[3]}` : ''
}

const Review = ({
  handleStepUpdate,
  companyDetail,
  uploadedFile,
  selectedOption,
  users
}) => {
  return (
    <Row className='review review-page'>
      <Text className='title-text'>Step 3 - Review and Submit</Text>
      <Card
        extra={<a onClick={() => handleStepUpdate(0)}>Edit</a>}
        style={{ width: '100%' }}
        title='Company Details'
      >
        <Form className='desktop' layout='vertical'>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label='ABC Michigan Chapter'>
                <Input disabled value={companyDetail?.chapter} />
              </Form.Item>
            </Col>
            <Col lg={8} span={8} xl={8} xs={24}>
              <Form.Item label='Company Name'>
                <Input disabled value={companyDetail?.orgName} />
              </Form.Item>
              <Form.Item label='City'>
                <Input disabled value={companyDetail?.city} />
              </Form.Item>
              <Form.Item label='Your First Name'>
                <Input disabled value={companyDetail?.deputyFirstName} />
              </Form.Item>
              <Form.Item label='Phone Number'>
                <Input
                  disabled
                  value={formatPhoneNumber(companyDetail?.phoneNumber)}
                />
              </Form.Item>
              <Form.Item label='President/Owner First Name'>
                <Input disabled value={companyDetail?.orgPresidentFirstName} />
              </Form.Item>
            </Col>
            <Col lg={8} span={8} xl={8} xs={24}>
              <Form.Item label='Address Line 1'>
                <Input disabled value={companyDetail?.orgAddress1} />
              </Form.Item>
              <Form.Item label='State'>
                <Input disabled value={companyDetail?.state} />
              </Form.Item>
              <Form.Item label='Your Last Name'>
                <Input disabled value={companyDetail?.deputyLastName} />
              </Form.Item>
              <Form.Item label='Email Address'>
                <Tooltip
                  placement='topLeft'
                  title={companyDetail?.deputyEmailAddress}
                  trigger='hover'
                >
                  <Input readOnly value={companyDetail?.deputyEmailAddress} />
                </Tooltip>
              </Form.Item>
              <Form.Item label='President/Owner Last Name'>
                <Input disabled value={companyDetail?.orgPresidentLastName} />
              </Form.Item>
            </Col>
            <Col lg={8} span={8} xl={8} xs={24}>
              <Form.Item label='Address Line 2'>
                <Input disabled value={companyDetail?.orgAddress2} />
              </Form.Item>
              <Form.Item label='Zip Code'>
                <Input disabled value={companyDetail?.zipCode} />
              </Form.Item>
              <Form.Item label='Your Title'>
                <Input disabled value={companyDetail?.deputyTitle} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form className='mobile' layout='vertical'>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label='ABC Michigan Chapter'>
                <Input disabled value={companyDetail?.chapter} />
              </Form.Item>
              <Form.Item label='Company Name'>
                <Input disabled value={companyDetail?.orgName} />
              </Form.Item>
              <Form.Item label='Address Line 1'>
                <Input disabled value={companyDetail?.orgAddress1} />
              </Form.Item>
              <Form.Item label='Address Line 2'>
                <Input disabled value={companyDetail?.orgAddress2} />
              </Form.Item>
              <Form.Item label='City'>
                <Input disabled value={companyDetail?.city} />
              </Form.Item>
              <Form.Item label='State'>
                <Input disabled value={companyDetail?.state} />
              </Form.Item>
              <Form.Item label='Zip Code'>
                <Input disabled value={companyDetail?.zipCode} />
              </Form.Item>
              <Form.Item label='Your First Name'>
                <Input disabled value={companyDetail?.deputyFirstName} />
              </Form.Item>
              <Form.Item label='Your Last Name'>
                <Input disabled value={companyDetail?.deputyLastName} />
              </Form.Item>
              <Form.Item label='Your Title'>
                <Input disabled value={companyDetail?.deputyTitle} />
              </Form.Item>
              <Form.Item label='Phone Number'>
                <Input
                  disabled
                  value={formatPhoneNumber(companyDetail?.phoneNumber)}
                />
              </Form.Item>
              <Form.Item label='Email Address'>
                <Input readOnly value={companyDetail?.deputyEmailAddress} />
              </Form.Item>
              <Form.Item label='President/Owner First Name'>
                <Input disabled value={companyDetail?.orgPresidentFirstName} />
              </Form.Item>
              <Form.Item label='President/Owner Last Name'>
                <Input disabled value={companyDetail?.orgPresidentLastName} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        className='employee-info-review-card-dekstop'
        extra={<a onClick={() => handleStepUpdate(1)}>Edit</a>}
        style={{ width: '100%', marginTop: 12 }}
        title='Employee Details'
      >
        <Table
          columns={columns}
          dataSource={users}
          pagination={false}
          rowClassName={(record) => (record.valid ? '' : 'invalid-row')}
          size='middle'
        />
      </Card>
      <Card
        className='employee-info-review-card-mobile'
        extra={<a onClick={() => handleStepUpdate(1)}>Edit</a>}
        style={{ width: '100%', marginTop: 12 }}
        title='Employee Details'
      >
        <>
          {users.map((user, index) => (
            <div key={index} style={{ marginBottom: 35 }}>
              First Name: <Input disabled value={user.firstName} />
              Last Name: <Input disabled value={user.lastName} />
              Email: <Input disabled value={user.emailAddress} />
            </div>
          ))}
        </>
      </Card>
    </Row>
  )
}

Review.propTypes = {
  companyDetail: PropTypes.object,
  handleStepUpdate: PropTypes.func,
  selectedOption: PropTypes.number,
  uploadedFile: PropTypes.object,
  users: PropTypes.array
}

const mapStateToProps = ({
  companyInfo,
  contactInfo,
  employeeInfo,
  bulkUpload
}) => ({
  companyDetail: companyInfo.companyDetail,
  selectedOption: employeeInfo.selectedOption,
  uploadedFile: bulkUpload.uploadedFile,
  users: contactInfo.users
})

const mapDispatchToProps = {
  handleStepUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(Review)
