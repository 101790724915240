import {
  ADD_USER,
  DELETE_USER,
  GET_USERS_LIST,
  UPDATE_USERS,
  UPDATE_COMPANY_USER
} from '../actionTypes'

const initialState = {
  isUsersEmpty: true,
  users: [
    {
      emailAddress: '',
      firstName: '',
      lastName: '',
      valid: true
    },
    {
      emailAddress: '',
      firstName: '',
      lastName: '',
      valid: true
    }
  ]
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST:
      return {
        ...state,
        ...action.payload
      }
    case DELETE_USER:
      return {
        ...state,
        ...action.payload
      }
    case ADD_USER:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_USERS:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_COMPANY_USER:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
