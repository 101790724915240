import {
  GET_BULK_UPLOAD_DETAILS,
  UPLOAD_FILE_TO_STORE,
  RESOURCE_UPLOAD,
  VALIDATE_UPLOAD
} from '../actionTypes'

const initialState = {
  fileList: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BULK_UPLOAD_DETAILS:
      return {
        ...state,
        ...action.payload
      }
    case UPLOAD_FILE_TO_STORE:
      return {
        ...state,
        uploadedFile: action.payload
      }
    case RESOURCE_UPLOAD:
      return {
        ...state,
        ...action.payload
      }
    case VALIDATE_UPLOAD:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
