export const ADD_FORM_BASED_COLLEGUES = 'ADD_FORM_BASED_COLLEGUES'
export const ADD_MORE_USER = 'ADD_MORE_USER'
export const ADD_USER = 'ADD_USER'
export const DELETE_USER = 'DELETE_USER'
export const EDIT_COMPANY = 'EDIT_COMPANY'
export const EDIT_CONTACT = 'EDIT_CONTACT'
export const GET_ALL_SUB_ORGS = 'GET_ALL_SUB_ORGS'
export const GET_BULK_UPLOAD_DETAILS = 'GET_BULK_UPLOAD_DETAILS'
export const GET_CHAPTERS = 'GET_CHAPTERS'
export const GET_HOME_OPTIONS = 'GET_HOME_OPTIONS'
export const GET_UPLOAD_BY_COLLEAGUE = 'GET_UPLOAD_BY_COLLEAGUE'
export const GET_USERS_LIST = 'GET_USERS_LIST'
export const LOAD_USER_LIST = 'LOAD_USER_LIST'
export const PUBLISH_USER_LIST = 'PUBLISH_USER_LIST'
export const RESET_ADMIN_MESSAGE = 'RESET_ADMIN_MESSAGE'
export const RESOURCE_UPLOAD = 'RESOURCE_UPLOAD'
export const SEND_EMAIL = 'SEND_EMAIL'
export const SUMMARY_REPORT = 'SUMMARY_REPORT'
export const SUMMARY_REPORT_BY_SUB_ORG_COMPANY_CD = 'SUMMARY_REPORT_BY_SUB_ORG_COMPANY_CD'
export const SUMMARY_REPORT_BY_SUB_ORG_TYPE = 'SUMMARY_REPORT_BY_SUB_ORG_TYPE'
export const SUMMARY_REPORT_BYSUB_ORG = 'SUMMARY_REPORT_BYSUB_ORG'
export const UPDATE_COMPANY_DETAIL = 'UPDATE_COMPANY_DETAIL'
export const UPDATE_COMPANY_USER = 'UPDATE_COMPANY_USER'
export const UPDATE_EMPLOYEES = 'UPDATE_EMPLOYEES'
export const UPDATE_HOME_OPTIONS = 'UPDATE_HOME_OPTIONS'
export const UPDATE_ORG_DETAILS = 'UPDATE_ORG_DETAILS'
export const UPDATE_STEP = 'UPDATE_STEP'
export const UPDATE_USERS = 'UPDATE_USERS'
export const UPLOAD_FILE_TO_STORE = 'UPLOAD_FILE_TO_STORE'
export const VALIDATE_UPLOAD = 'VALIDATE_UPLOAD'
